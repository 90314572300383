import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import SplitPane from "react-split-pane";
import RandomizeSideData from "./RandomizeSideData";
import RandomizeTableData from "./RandomizeTableData";
import { SpinerDataLoader } from "../../NoDataFoundTable/NoDataFoundTable";
import EnvelopeNewDesign from "../TrialDesign/EnvelopDesign/EnevelopNewDesign";
import RandomizeFilterData from "./RandomizeFilterData";
import UseToggle from "../../../Hooks/UseToggle";
import { randomizerServices } from "../../../Services/IWRS/randomizerServices";
import { ctmsServices } from "../../../Services/studyServices/ctmsServices";
import { studyOnboardingService } from "../../../Services/studyServices/studySubjectsServices";
import { Spinner } from "react-bootstrap";
import RandomizeExportData from "./RandomizeExportData";
import RequestAllData from "./RequestAllData";
const Randomize = ({
  onMouseDown,
  tableRef,
  randomizerId,
  setUpdateType,
  updateType,
  requestedData,
  setRequestedData,
  getRandomizerMetadataData,
  siteList,
  seedNumber
}) => {

  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } = UseToggle();
  const [loader, setLoader] = useState(false);
  const [randomizerList, setRandomizerList] = useState([])
  const [randomizationNumber, setRandomizationNumber] = useState("")
  const [subjectRandomNumber, setSubjectRandomNumber] = useState("")
  const [subjectFactor, setSubjectFactor] = useState("")
  const [randomizeStatus, setRandomizeStatus] = useState("");
  const [loadData, setLoadData] = useState(false);
  const { studyId } = useParams();
  const [isTableView, setIsTableView] = useState(false)
  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));
  let [siteId, setSiteId] = useState("");

  const iwrsPermissionData = JSON.parse(
    localStorage.getItem("iwrsPermissionData")
  );

  const handleAddNewLearning = () => {
    setUpdateType("add");
  };

  const findRandomizerStudyParameter = async (randomizerId) => {
    let data = await randomizerServices.findRandomizerStudyParameter(studyId, randomizerId);
    setRandomizeStatus(data?.data?.randomizeStatus);
  };

  const [factorData, setFactorData] = useState([])
  const [selectOption, setSelectOption] = useState();
  const [treatment, setTreatment] = useState([])
  const [columnId, setColumnId] = useState("")
  const getStudySubjectColumnForFactor = async () => {
    let data = await studyOnboardingService.getStudySubjectColumnForFactor(studyId)
    setFactorData(data?.data || [])
  }

  const findUpdateRandomizerFactorOrder = async () => {
    let data = await randomizerServices.findUpdateRandomizerFactorOrder(studyId)
    setSelectOption(data?.data)
  }

  const getStudyTreatmentColumnData = async () => {
    let data = await ctmsServices.getStudyTreatmentColumnData(studyId);
    let initialData = data?.data === undefined ? "" : data?.data;
    if (initialData.length > 0) {
      let finalData = initialData?.sort();
      setColumnId(finalData?.[0]._id);
    } else {
      setColumnId("");
    }
  };

  const getStudyTreatmentRowData = async () => {
    let data = await ctmsServices.getStudyTreatmentRowData(
      studyId,
      columnId
    );
    let initialData = Object.values(data?.data === undefined ? "" : data?.data);
    let finalData = initialData?.sort()?.reverse();
    setTreatment(finalData)
  };

  const [formData, setFormData] = useState({});

  const handleSelectChange = (fieldName, selectedValue, index) => {
    setFormData((prev) => ({
      ...prev,
      [`fieldName${index}`]: fieldName,
      [`value${index}`]: selectedValue,
    }));
  };

  useEffect(() => {
    getStudyTreatmentRowData();
  }, [columnId]);

  const findRandomizedData = async () => {
    setLoadData(true)
    let data = await randomizerServices.findRandomizedData(studyId, randomizerId, studyMode)
    let loadData = data?.data || []
    let finalData = loadData?.filter((item) => siteList?.map(data => data.siteIdLabel)?.includes(item.site));
    setRandomizerList(finalData)
    setLoadData(false)
  }
  const studyRandomizerAtOnce = async () => {
    setLoader(true)
    let userData = {}
    userData.studyMode = studyMode
    userData.studyId = studyId
    userData.randomizerId = randomizerId
    userData.siteList = siteList
    userData.treatment = treatment
    let data = await randomizerServices.studyRandomizerAtOnce(userData)
    if (data?.statusCode === 200) {
      findRandomizedData()
    }
    setLoader(false)
    getRandomizerMetadataData()
  }
  const studyRandomizerSingleRandomize = async () => {
    setLoader(true)
    let userData = {}
    userData.studyMode = studyMode
    userData.factor = formData
    userData.randomizerId = randomizerId
    userData.randomizationNumber = randomizationNumber
    userData.siteId = siteId
    userData.studyId = studyId
    userData.treatment = treatment
    let data = await randomizerServices.studyRandomizerSingleRandomize(userData)
    if (data?.statusCode === 200) {
      findRandomizedData()
    }
    setLoader(false)
    setUpdateType("")
    setSiteId("")
    setRandomizationNumber("")
    setSubjectRandomNumber("")
    setSubjectFactor("")
    setFormData({})
    getRandomizerMetadataData()
  }

  useEffect(() => {
    findRandomizedData();
  }, [randomizerId])

  useEffect(() => {
    getStudyTreatmentColumnData();
    getStudySubjectColumnForFactor();
    findUpdateRandomizerFactorOrder()
    findRandomizerStudyParameter(randomizerId);
  }, [studyId, randomizerId])

  let generateRandomizationIWRS = iwrsPermissionData?.generateRandomizationIWRSQC || iwrsPermissionData?.generateRandomizationIWRSLive
  let unblindIWRS = iwrsPermissionData?.unblindIWRSQC || iwrsPermissionData?.unblindIWRSLive

  let approvalOneIWRS = iwrsPermissionData?.approvalOneIWRSQC || iwrsPermissionData?.approvalOneIWRSLive
  let approvalTwoIWRS = iwrsPermissionData?.approvalTwoIWRSQC || iwrsPermissionData?.approvalTwoIWRSLive
  let approvalThreeIWRS = iwrsPermissionData?.approvalThreeIWRSQC || iwrsPermissionData?.approvalThreeIWRSLive
  let approvalFourIWRS = iwrsPermissionData?.approvalFourIWRSQC || iwrsPermissionData?.approvalFourIWRSLive
  let approvalFiveIWRS = iwrsPermissionData?.approvalFiveIWRSQC || iwrsPermissionData?.approvalFiveIWRSLive
  let approvalIWRS = approvalOneIWRS || approvalTwoIWRS || approvalThreeIWRS || approvalFourIWRS || approvalFiveIWRS
  return (
    <>
      <div className="position-relative">
        <div className="p-1 hstack justify-content-between border-bottom">
          <div className="hstack gap-2 toolbar">
            {(studyMode === "DBP MODE" || generateRandomizationIWRS) &&
              <>
                {updateType ? (
                  <i
                    onClick={() => setUpdateType("")}
                    title="Table Full Mode"
                    className="fa-solid fa-table-list CP"
                  ></i>
                ) : (
                  loader ?
                    <Spinner style={{ height: 15, width: 15, cursor: "no-drop" }} />
                    :
                    <i
                      onClick={randomizeStatus === "no" ? handleAddNewLearning : studyRandomizerAtOnce}
                      title={randomizeStatus === "no" ? "Single Randomize" : "Randomize At once"}
                      className="fa-solid fa-shuffle CP"
                    />
                )}
              </>
            }
            <div className="vr"></div>
            <i
              onClick={setToggle}
              title="Filter"
              className="fa-solid fa-filter CP"
            ></i>
            <div className="vr"></div>
            <i
              onClick={setToggle1}
              title="Export"
              className="fa-solid fa-download CP"
            ></i>
          </div>

          {/* <button
            onClick={handleToggleView}
            className="m-0 CP bg-main   text-white hstack"
            title={isTableView ? "Switch to Grid View" : "Switch to Table View"}
            style={{ fontSize: 13 }}
          >
            <i className={`fa-solid ${isTableView ? "fa-toggle-on" : "fa-toggle-off"} me-2`}></i>
            {isTableView ? "Grid View" : "Table View"}
          </button> */}
          {!unblindIWRS && !approvalIWRS &&
            <button
              disabled={requestedData?.status}
              style={{ fontSize: 13, borderRadius: 3 }}
              onClick={setToggle2}
              className="m-0 CP bg-main text-white hstack"
            >
              Request All
            </button>
          }
        </div>
        {isTableView ? (
          <EnvelopeNewDesign />
        ) : (
          <SplitPane
            split="horizontal"
            style={{ height: "calc(100vh - 412px)" }}
            className="position-relative"
            defaultSize={"100%"}
          >
            <SplitPane
              split="vertical"  
              minSize={20}
              defaultSize={updateType ? "25%" : "100%"}
            >
              {loadData ? (
                <SpinerDataLoader />
              ) : (
                updateType && (
                  <RandomizeSideData
                    siteId={siteId}
                    studyMode={studyMode}
                    setSiteId={setSiteId}
                    siteList={siteList}
                    randomizerList={randomizerList}
                    randomizationNumber={randomizationNumber}
                    setRandomizationNumber={setRandomizationNumber}
                    setSubjectRandomNumber={setSubjectRandomNumber}
                    setSubjectFactor={setSubjectFactor}
                    subjectRandomNumber={subjectRandomNumber ? JSON.parse(subjectRandomNumber) : []}
                    subjectFactor={subjectFactor ? JSON.parse(subjectFactor) : []}
                    studyRandomizerSingleRandomize={studyRandomizerSingleRandomize}
                    factorData={factorData}
                    handleSelectChange={handleSelectChange}
                    selectOption={selectOption}
                    formData={formData}
                    seedNumber={seedNumber}
                  />
                )
              )}
              {loadData ? (
                <SpinerDataLoader />
              ) : (
                <RandomizeTableData
                  unblindIWRS={unblindIWRS}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  requestedData={requestedData}
                  findRandomizedData={findRandomizedData}
                  randomizerList={randomizerList}
                  studyMode={studyMode}
                />
              )}
            </SplitPane>
          </SplitPane>
        )}
      </div>
      <RandomizeFilterData
        Show={!toggle}
        Hide={setToggle}
        Title={"Randomize Properties"}
      />
      <RandomizeExportData
        Show={!toggle1}
        Hide={setToggle1}
        Title={"Export Data"}
        studyId={studyId}
        randomizedId={requestedData?._id}
      />
      <RequestAllData
        Show={!toggle2}
        Hide={setToggle2}
        Title={"Request All"}
        setRequestedData={setRequestedData}
        requestedData={requestedData}
        getRandomizerMetadataData={getRandomizerMetadataData}
      />
    </>
  );
};

export default Randomize;
