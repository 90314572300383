import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { FaRegTrashAlt } from "react-icons/fa";
import Pagination from "../../../../ETMF/Pagination/Pagination";

const DictionariesTableData = ({ CodeList, tableRef, onMouseDown }) => {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = CodeList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(CodeList?.length / itemsPerPage);
  return (
    <>
      <Table
        tableRef={tableRef}
        id="resizable-table"
        className="text-nowrap mb-1 custom-table" 
        hover
        striped
      >
        <thead className="position-sticky z-1" style={{ top: -1 }}>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 40 }}>Sr.</th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>ID</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Name</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)}>Data Type</th>
            <th onMouseDown={(e) => onMouseDown(e, 5)}>Dictionary</th>
            <th onMouseDown={(e) => onMouseDown(e, 6)}>Version</th>
            <th onMouseDown={(e) => onMouseDown(e, 7)} style={{ width: 60 }}>
              <div className="text-center">Delete</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {result?.map((item, index) => (
            <tr key={index} 
            onClick={() => {
              setHighlightedRow(index);
            }}
            className={`position-relative cursor-pointer ${highlightedRow === index && "activeMenu"
              }`}
            >
              <td>{(pageNumber - 1) * itemsPerPage + index + 1}</td>
              <td>{item.codeListId}</td>
              <td>{item.codeListName}</td>
              <td>{item.nciClodeListCode}</td>
              <td>{item.nciClodeListCode}</td>
              <td>{item.nciClodeListCode}</td>
              <td>
                <div className="text-center">
                  {item.type === "post" ? (
                    <FaRegTrashAlt
                      title="Delete Protocol"
                      className="text-danger"
                    />
                  ) : (
                    <FaRegTrashAlt
                      title="No Delete Pre-Define Prorocol"
                      className="text-muted"
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={CodeList?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default DictionariesTableData;
