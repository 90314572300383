import config from "../../config/config.json";
// import saveAs from "file-saver";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const StudyVisitFormServices = {
  sendForReviewStudyVisitForm,
  getStudyVisitsFormAttributeData,
  getStudyVisitsFormStatusAttributeData,
  deleteStudyVisitFormsData,
  updateStudyVisitFormsMeta,
  getStudyVisitsFormData,
  getStudyVisitsFormDataForReviewer,
  filterStudyVisitsFormData,
  filterStudyVisitsFormDataForReviewer,
  getStudyFormValidationData,
  duplicateStudyVisitFormsMeta,
  duplicateAssignStudyVisitFormsMeta,

  getQuerySummeryDataForAllForm,
  filterQuerySummeryDataForAllForm,
  getManageQueryStatusDataForAllFormField,
  filterManageQueryStatusDataForAllFormField,
  viewFormFieldAttributeBasedonFormFieldId,
  veiwTicketQueryReplyComments,

  getFormSDVData,
  filterFormSDVData,
  getLogFormSDVData,
  getSpecialFormSDVData,
  getSpecialLogFormSDVData,
  viewFormAttributeBasedonFormUniqueId,
  
  getFormFieldSDVData,
  filterFormFieldSDVData,
  getLogFormFieldSDVData,
  getSpecialFormFieldSDVData,
  getSpecialLogFormFieldSDVData,
  viewFormFieldAttributeBasedonFormUniqueId,

  getSDVStatistic,
  filterSDVStatistic,
  getSDVLogStatistic,
  getSpecialSDVStatistic,
  getSpecialLogSDVStatistic,
  getAllFormField,

  getStudySiteId,
  getStudySubjectId,
  getStudyVisitId,
  checkDataEntryStudyVisitId,
  getStudyVisitIdBasedOnAssignForm,
  assignFormInitiateVisits,
  unassignFormInitiateVisits,

  //Special Form
  generateStudySpecialFormQuery,
  getStudySpecialFormQueryList,
  assignStudySpecialFormQuery,
  updateStatusStudySpecialFormQuery,
  getStudySpecialFormQueryDiscussionList,
  sendStudySpecialFormQueryDiscussion,

  //Visit Form
  generateStudyVisitFormQuery,
  getStudyVisitFormQueryList,
  assignStudyVisitFormQuery,
  updateStatusStudyVisitFormQuery,
  getStudyVisitFormQueryDiscussionList,
  sendStudyVisitFormQueryDiscussion,
  getStudyVisitFormReviewData
};

async function getStudyVisitsFormAttributeData(endPoint, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitsFormStatusAttributeData(endPoint, studyId, dataEntryMode, studyUniqueId, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${studyId}/${dataEntryMode}/${studyUniqueId}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyFormValidationData() {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyFormValidationData`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function deleteStudyVisitFormsData(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}deleteStudyVisitsFormsData/${id}`,
      headersWithAuthWithoutBody("DELETE", headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function updateStudyVisitFormsMeta(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}updateStudyVisitsFormsMeta`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
  } catch (error) {
    console.log(error);
  }
}

async function duplicateStudyVisitFormsMeta(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}duplicateStudyVisitFormsMeta`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function duplicateAssignStudyVisitFormsMeta(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}duplicateAssignStudyVisitFormsMeta`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function filterManageQueryStatusDataForAllFormField(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterManageQueryStatusDataForAllFormField`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function filterFormFieldSDVData(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterFormFieldSDVData`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function filterFormSDVData(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterFormSDVData`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function filterSDVStatistic(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterSDVStatistic`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function sendForReviewStudyVisitForm(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}sendForReviewStudyVisitForm`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitsFormData(studyId, visitId, page, limit) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyVisitsFormData/${studyId}/${visitId}/${page}/${limit}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitsFormDataForReviewer(studyId, studyUniqueId, StudyMode, page, limit) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyVisitsFormDataForReviewer/${studyId}/${studyUniqueId}/${StudyMode}/${page}/${limit}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

//Filter Study Visit Form
async function filterStudyVisitsFormData(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterStudyVisitsFormData`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

//Filter Study Visit Form for Review
async function filterQuerySummeryDataForAllForm(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterQuerySummeryDataForAllForm`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

//Filter Study Visit Form for Review
async function filterStudyVisitsFormDataForReviewer(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterStudyVisitsFormDataForReviewer`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getQuerySummeryDataForAllForm(StudyMode, id, siteUniqueId, subjectUniqueId, visitUniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getQuerySummeryDataForAllForm/${StudyMode}/${id}/${siteUniqueId}/${subjectUniqueId}/${visitUniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getManageQueryStatusDataForAllFormField(StudyMode, id, siteUniqueId, subjectUniqueId, visitUniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getManageQueryStatusDataForAllFormField/${StudyMode}/${id}/${siteUniqueId}/${subjectUniqueId}/${visitUniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function viewFormFieldAttributeBasedonFormFieldId(formUniqueId, formFieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}viewFormFieldAttributeBasedonFormFieldId/${formUniqueId}/${formFieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function veiwTicketQueryReplyComments(studyMode, studyUniqueId, ticketId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}veiwTicketQueryReplyComments/${studyMode}/${studyUniqueId}/${ticketId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getFormSDVData(StudyMode, id, siteUniqueId, subjectUniqueId, visitUniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getFormSDVData/${StudyMode}/${id}/${siteUniqueId}/${subjectUniqueId}/${visitUniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getLogFormSDVData(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getLogFormSDVData/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSpecialFormSDVData(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSpecialFormSDVData/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSpecialLogFormSDVData(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSpecialLogFormSDVData/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function viewFormAttributeBasedonFormUniqueId(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}viewFormAttributeBasedonFormUniqueId/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getFormFieldSDVData(StudyMode, id, siteUniqueId, subjectUniqueId, visitUniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getFormFieldSDVData/${StudyMode}/${id}/${siteUniqueId}/${subjectUniqueId}/${visitUniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getLogFormFieldSDVData(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getLogFormFieldSDVData/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSpecialFormFieldSDVData(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSpecialFormFieldSDVData/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSpecialLogFormFieldSDVData(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSpecialLogFormFieldSDVData/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function viewFormFieldAttributeBasedonFormUniqueId(formUniqueId, uniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}viewFormFieldAttributeBasedonFormUniqueId/${formUniqueId}/${uniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSDVStatistic(StudyMode, id, siteUniqueId, subjectUniqueId, visitUniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSDVStatistic/${StudyMode}/${id}/${siteUniqueId}/${subjectUniqueId}/${visitUniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSDVLogStatistic(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSDVLogStatistic/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSpecialSDVStatistic(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSpecialSDVStatistic/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSpecialLogSDVStatistic(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSpecialLogSDVStatistic/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getAllFormField(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getAllFormField/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudySiteId(studyId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudySiteId/${studyId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudySubjectId(studyId, siteId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudySubjectId/${studyId}/${siteId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitId(studyId, uniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyVisitId/${studyId}/${uniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitIdBasedOnAssignForm(studyId, uniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyVisitIdBasedOnAssignForm/${studyId}/${uniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function checkDataEntryStudyVisitId(studyId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}checkDataEntryStudyVisitId/${studyId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function assignFormInitiateVisits(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}assignFormInitiateVisits`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    handleResponse(data)
    return data
  } catch (error) {
    console.log(error);
  }
}

async function unassignFormInitiateVisits(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}unassignFormInitiateVisits`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
  } catch (error) {
    console.log(error);
  }
}

//Generate Special Form Query
async function generateStudySpecialFormQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}generateStudySpecialFormQuery`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

//Generate Special Form Query
async function assignStudySpecialFormQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignStudySpecialFormQuery`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

//Update Status Special Form Query
async function updateStatusStudySpecialFormQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStatusStudySpecialFormQuery`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

async function getStudySpecialFormQueryList(formUniqueId, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySpecialFormQueryList/${formUniqueId}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySpecialFormQueryDiscussionList(queryId, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySpecialFormQueryDiscussionList/${queryId}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Send Study Special Form Query Discussion
async function sendStudySpecialFormQueryDiscussion(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}sendStudySpecialFormQueryDiscussion`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Generate Visit Form Query
async function generateStudyVisitFormQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}generateStudyVisitFormQuery`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

//Generate Visit Form Query
async function assignStudyVisitFormQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignStudyVisitFormQuery`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

//Update Status Visit Form Query
async function updateStatusStudyVisitFormQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStatusStudyVisitFormQuery`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

async function getStudyVisitFormQueryList(formUniqueId, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyVisitFormQueryList/${formUniqueId}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyVisitFormQueryDiscussionList(queryId, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyVisitFormQueryDiscussionList/${queryId}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyVisitFormReviewData(studyUniqueId, formUniqueId, type) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyVisitFormReviewData/${studyUniqueId}/${formUniqueId}/${type}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Send Study Visit Form Query Discussion
async function sendStudyVisitFormQueryDiscussion(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}sendStudyVisitFormQueryDiscussion`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}