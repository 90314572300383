import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Table } from "react-bootstrap";
import SplitPane from "react-split-pane";

const RandomizeTabPropertiesData = ({
  onMouseDown,
  tableRef,
  randomizerData,
}) => {
  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "89%" }}
      >
        <div className="table-container tableLibrarySection">
          <Table
            id="resizable-table"
            striped
            className="m-0 custom-table"
            ref={tableRef}
            bordered
          >
            <thead className="thead-sticky" style={{ top: -1 }}>
              <tr>
                <th onMouseDown={(e) => onMouseDown(e, 0)}>Attribute</th>
                <th onMouseDown={(e) => onMouseDown(e, 1)}>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{randomizerData?.name}</td>
              </tr>

              <tr>
                <td>Description</td>
                <td>{randomizerData?.description}</td>
              </tr>

              <tr>
                <td>Last Randomization Date | Time</td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm:ss">
                    {randomizerData?.updatedAt}
                  </Moment>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </SplitPane>
    </>
  );
};

export default RandomizeTabPropertiesData;
