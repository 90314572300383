import React, { useEffect, useState } from "react";
import RandomizeTabPropertiesData from "./RandomizeTabPropertiesData";
import RandomizeTabActivityData from "./RandomizeTabActivityData";
import RandomizeTabRequestData from "./RandomizeTabRequestData";
import Randomize from "./Randomize";
import { useParams } from "react-router";
import { studyAccessManagement } from "../../../Services/studyAccessManagement";
import { randomizerServices } from "../../../Services/IWRS/randomizerServices";

const RandomizeTabData = ({
  setIsSectionVisible,
  tableRef,
  onMouseDown,
  randomizerData,
  randomizerId,
  setUpdateType,
  updateType,
  requestedData,
  setRequestedData,
  getRandomizerMetadataData
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [siteList, setSiteList] = useState([])
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const [activeTabs, setActiveTabs] = useState("properties");
  const iwrsPermissionData = JSON.parse(
    localStorage.getItem("iwrsPermissionData")
  );

  let LiveSite = iwrsPermissionData?.siteIdForLive;
  let QCSite = iwrsPermissionData?.siteIdForQC;

  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const { studyId } = useParams();

  const studySiteListData = async () => {
    let data = await studyAccessManagement.studyRandomizerSiteListData(studyId, studyMode)
    let finalData = data?.data || []
    if (studyMode === "Live Mode") {
      let data = finalData?.filter((item) => LiveSite?.includes(item.siteId));
      setSiteList(data);
    } else if (studyMode === "QC Mode") {
      let data = finalData?.filter((item) => QCSite?.includes(item.siteId));
      setSiteList(data);
    }
  }

  const [seedNumber, setSeedNumber] = useState("");
  const findRandomizerStudyParameter = async () => {
    let data = await randomizerServices.findRandomizerStudyParameter(
      studyId,
      randomizerData?._id
    );
    setSeedNumber(studyMode === "DBP MODE" ? data?.data?.randomNumberDBPSeed :
      studyMode === "Live Mode" ? data?.data?.randomNumberLiveSeed :
        studyMode === "QC Mode" ? data?.data?.randomNumberQCSeed : ""
    );
  };

  useEffect(() => {
    findRandomizerStudyParameter();
  }, [studyId, randomizerData]);

  useEffect(() => {
    studySiteListData()
  }, [])
  return (
    <>
      <div
        className={`pt-1 bg-light ${isFullScreen ? "full-screen-component" : ""
          }`}
      >
        <div className="hstack justify-content-between border-bottom">
          <div className="hstack me-1 gap-1">
            {["properties", "randomize", "request", "activity"].map((tab) => (
              <button
                key={tab}
                className={`buttonForTabs ${activeTabs === tab ? "activee" : ""
                  }`}
                onClick={() => setActiveTabs(tab)}
                title={tab.charAt(0).toUpperCase() + tab.slice(1)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
          <div className="d-flex gap-1">
            <div className="toolbar">
              <i
                className="fa-solid fa-angle-down"
                onClick={() => setIsSectionVisible("")}
              ></i>
            </div>
            <div className="toolbar">
              <i
                className={`fa-solid me-2 ${!isFullScreen ? "fa-maximize" : "fa-minimize"
                  }`}
                title={!isFullScreen ? "Full Mode" : "Original Mode"}
                onClick={toggleFullScreen}
              ></i>
            </div>
          </div>
        </div>
      </div>
      {activeTabs === "properties" ? (
        <RandomizeTabPropertiesData
          tableRef={tableRef}
          onMouseDown={onMouseDown}
          randomizerData={randomizerData}
        />
      ) : activeTabs === "request" ? (
        <RandomizeTabRequestData
          tableRef={tableRef}
          onMouseDown={onMouseDown}
          randomizerId={randomizerId}
          siteList={siteList}
        />
      ) : activeTabs === "randomize" ? (
        <Randomize
          tableRef={tableRef}
          onMouseDown={onMouseDown}
          randomizerId={randomizerId}
          setUpdateType={setUpdateType}
          updateType={updateType}
          setRequestedData={setRequestedData}
          requestedData={requestedData}
          getRandomizerMetadataData={getRandomizerMetadataData}
          siteList={siteList}
          seedNumber={seedNumber}
        />
      ) : activeTabs === "activity" ? (
        <RandomizeTabActivityData
          tableRef={tableRef}
          onMouseDown={onMouseDown}
          randomizedId={randomizerId}
          siteList={siteList}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default RandomizeTabData;
