import React, { useState } from "react";
import { Table } from "react-bootstrap";
import SplitPane from "react-split-pane";
import Pagination from "../../../../../ETMF/Pagination/Pagination";
import Moment from "react-moment";

const StudySiteTableData = ({
  handleAddNewTab,
  studyDynamicColumn,
  studyDynamicRow,
  hideColumn,
  setIdUpdateRowTable,
  setSubjectUniqueId,
  setIdReviewRowTable,
  handleSubmit3,
  getStudyRightSiteData,
  setIsSectionVisible,
  tableRef,
  onMouseDown,
  totalPage,
  startIndex,
  pageNumber,
  itemsPerPage,
  totalItems,
  setPageNumber,
  setItemsPerPage,
  setUpdatedType,
  seIdGenerateQuery,
  viewTypeData,
  setSubjectData
}) => {
  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const studyPermissionData = JSON.parse(
    localStorage.getItem("studyPermissionData")
  );

  const [selectedRow, setSelectedRow] = useState(null);

  const viewStudySubjectData = (item) => {
    let idObject = {
      studySubjectState: item?.studyUniqueId,
      selectedStudySiteId: item?.siteIdLabel,
      siteIdData: item?._id,
    };
    let breadcrumb = [
      {
        tabName: `Site: ${item?.siteIdLabel}`,
        currentTabName: `Study Site`,
        uniqueId: `studySite`,
      },
      { tabName: `Subject List` },
    ];
    handleAddNewTab(
      `${item?.siteIdLabel} Subject`,
      "fa-solid fa-database",
      `studySubject_${item?._id}`,
      idObject,
      breadcrumb
    );
  };

  const isHighlightable = () =>
    studyPermissionData?.createDatabaseSites ||
    studyPermissionData?.viewMetaDataVerification ||
    studyPermissionData?.createDatabaseViewSiteManagement ||
    studyPermissionData?.createDatabaseVerifySiteManagement ||
    studyPermissionData?.dataCollectionDBPMode ||
    studyPermissionData?.dataCollectioninDVMode ||
    studyPermissionData?.siteIdForLive?.length > 0 ||
    studyPermissionData?.siteIdForQC?.length > 0 ||
    studyPermissionData?.siteIdForSDVLive?.length > 0 ||
    studyPermissionData?.siteIdForSDVQC?.length > 0;

  const getStatusColor = (status) => {
    switch (status) {
      case "open":
        return "red";
      case "cancel":
        return "grey";
      case "closed":
        return "green";
      case "inprogress":
        return "orange";
      default:
        return "black";
    }
  };

  return (
    <>
      <SplitPane split="vertical" minSize={20} defaultSize="100%">
        <>
          <Table ref={tableRef} className="m-0 custom-table" hover striped>
            <thead className="position-sticky z-1">
              <tr>
                <th
                  onMouseDown={(e) => onMouseDown(e, 0)}
                  style={{ width: 45 }}
                >
                  S No.
                </th>
                {hideColumn?.includes("Site Id") && (
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Site Id</th>
                )}
                {hideColumn?.includes("Number of Subject") && (
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>No of Subject</th>
                )}
                {studyDynamicColumn &&
                  studyDynamicColumn?.map(({ name }, i) => (
                    <th onMouseDown={(e) => onMouseDown(e, i + 2)}>{name}</th>
                  ))}
                {hideColumn?.includes("Subject Settings") && (
                  <th onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                    Subject Settings
                  </th>
                )}
                {hideColumn?.includes("Status") && viewTypeData === "edc" && (
                  <th onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                    Status
                  </th>
                )}
                {hideColumn?.includes("Query Status") && viewTypeData === "ctms" && (
                  <th onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                    Query Status
                  </th>
                )}
                {hideColumn?.includes("Query Date | Time") && viewTypeData === "ctms" && (
                  <th onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                    Query Date | Time
                  </th>
                )}
                {viewTypeData === "edc" && (
                  <th onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                    View Site Subject
                  </th>
                )}
                {(viewTypeData === "ctms" && StudyMode === "DBP MODE") && (
                  <>
                    <th className="text-center" style={{ width: 80 }} onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                      Update
                    </th>
                    <th className="text-center" style={{ width: 80 }} onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                      Delete
                    </th>
                  </>
                )}
                {(viewTypeData === "ctms" && StudyMode === "DV Mode") && (
                  <>
                    <th className="text-center" style={{ width: 80 }} onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                      Signature
                    </th>
                    <th className="text-center" style={{ width: 120 }} onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                      Generate Query
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {studyDynamicRow?.map((item, index) => (
                <tr
                  key={index}
                  style={{ cursor: "pointer" }}
                  className={
                    selectedRow === item.studyUniqueId &&
                    "highlighted"
                  }
                >
                  <td onClick={() => {
                    if (isHighlightable() || viewTypeData === "ctms") {
                      getStudyRightSiteData(item);
                      setSelectedRow(item?.studyUniqueId);
                      setSubjectUniqueId(item?.studyUniqueId);
                      setIsSectionVisible(true);
                      setSubjectData(item);
                    } else {
                      setIsSectionVisible(true);
                      setSubjectData(item);
                    }
                  }}>
                    {startIndex + index + 1}
                  </td>
                  {hideColumn?.includes("Site Id") && (
                    <td onClick={() => {
                      if (isHighlightable() || viewTypeData === "ctms") {
                        getStudyRightSiteData(item);
                        setSelectedRow(item?.studyUniqueId);
                        setSubjectUniqueId(item?.studyUniqueId);
                        setIsSectionVisible(true);
                        setSubjectData(item);
                      } else {
                        setIsSectionVisible(true);
                        setSubjectData(item);
                      }
                    }}>
                      {item?.siteIdLabel}
                    </td>
                  )}
                  {hideColumn?.includes("Number of Subject") && (
                    <td onClick={() => {
                      if (isHighlightable() || viewTypeData === "ctms") {
                        getStudyRightSiteData(item);
                        setSelectedRow(item?.studyUniqueId);
                        setSubjectUniqueId(item?.studyUniqueId);
                        setIsSectionVisible(true);
                        setSubjectData(item);
                      } else {
                        setIsSectionVisible(true);
                        setSubjectData(item);
                      }
                    }}>
                      {item?.subjectLimit}
                    </td>
                  )}
                  {Object?.values(item?.row).map((data, index) => (
                    <td key={index} onClick={() => {
                      if (isHighlightable() || viewTypeData === "ctms") {
                        getStudyRightSiteData(item);
                        setSelectedRow(item?.studyUniqueId);
                        setSubjectUniqueId(item?.studyUniqueId);
                        setIsSectionVisible(true);
                        setSubjectData(item);
                      } else {
                        setIsSectionVisible(true);
                        setSubjectData(item);
                      }
                    }}>
                      {data.valueData}
                    </td>
                  ))}
                  {hideColumn?.includes("Status") && viewTypeData === "edc" && (
                    <td onClick={() => {
                      if (isHighlightable() || viewTypeData === "ctms") {
                        getStudyRightSiteData(item);
                        setSelectedRow(item?.studyUniqueId);
                        setSubjectUniqueId(item?.studyUniqueId);
                        setIsSectionVisible(true);
                        setSubjectData(item);
                      } else {
                        setIsSectionVisible(true);
                        setSubjectData(item);
                      }
                    }}>
                      {item?.status ? "Completed" : "Ongoing"}
                    </td>
                  )}
                  {hideColumn?.includes("Subject Settings") && (
                    <td className="text-uppercase" onClick={() => {
                      if (isHighlightable() || viewTypeData === "ctms") {
                        getStudyRightSiteData(item);
                        setSelectedRow(item?.studyUniqueId);
                        setSubjectUniqueId(item?.studyUniqueId);
                        setIsSectionVisible(true);
                        setSubjectData(item);
                      } else {
                        setIsSectionVisible(true);
                        setSubjectData(item);
                      }
                    }}>
                      {item?.subjectSettings}
                    </td>
                  )}
                  {hideColumn?.includes("Query Status") && viewTypeData === "ctms" && (
                    <td onClick={() => {
                      if (isHighlightable() || viewTypeData === "ctms") {
                        getStudyRightSiteData(item);
                        setSelectedRow(item?.studyUniqueId);
                        setSubjectUniqueId(item?.studyUniqueId);
                        setIsSectionVisible(true);
                        setSubjectData(item);
                      } else {
                        setIsSectionVisible(true);
                        setSubjectData(item);
                      }
                    }}>
                      {item?.queryStatus ? (
                        <div
                          className="text-capitalize"
                          style={{
                            color: getStatusColor(item?.queryStatus),
                          }}
                        >
                          {item?.queryStatus}
                        </div>
                      ) : (
                        "No Query"
                      )}
                    </td>
                  )}
                  {hideColumn?.includes("Query Date | Time") && viewTypeData === "ctms" && (
                    <td onClick={() => {
                      if (isHighlightable() || viewTypeData === "ctms") {
                        getStudyRightSiteData(item);
                        setSelectedRow(item?.studyUniqueId);
                        setSubjectUniqueId(item?.studyUniqueId);
                        setIsSectionVisible(true);
                        setSubjectData(item);
                      } else {
                        setIsSectionVisible(true);
                        setSubjectData(item);
                      }
                    }}>
                      {item?.queryStatus === "open" ? (
                        <Moment format="DD MMM YYYY | HH:mm" withTitle>
                          {item?.queryDateTime}
                        </Moment>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  )}
                  {viewTypeData === "edc" && (
                    <td>
                      <button
                        disabled={
                          !(
                            studyPermissionData?.dataCollectionDBPMode ||
                            studyPermissionData?.dataCollectioninDVMode ||
                            studyPermissionData?.siteIdForLive?.length > 0 ||
                            studyPermissionData?.siteIdForQC?.length > 0 ||
                            studyPermissionData?.siteIdForSDVLive?.length >
                            0 ||
                            studyPermissionData?.siteIdForSDVQC?.length > 0
                          )
                        }
                        onClick={(e) => viewStudySubjectData(item)}
                        style={{
                          fontSize: 10,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                        className={`text-white border border-success bg-success`}
                      >
                        View Site Subject
                      </button>
                    </td>
                  )}
                  {(viewTypeData === "ctms" && StudyMode === "DBP MODE") && (
                    <>
                      <td className="text-center">
                        <button
                          onClick={() => {
                            setIdUpdateRowTable(
                              item?._id,
                              item?.studyUniqueId,
                              item?.row,
                              item?.siteIdLabel,
                              item?.subjectLimit,
                              item?.subjectSettings,
                              item?.subjectPrefix,
                              item?.subjectStartingNumber,
                              item?.subjectPostfix,
                            );
                            setUpdatedType("editRow");
                          }}
                          className="text-white border-success bg-success border"
                          style={{
                            fontSize: 10,
                            borderRadius: 3,
                            padding: "2px 8px",
                          }}
                        >
                          Update
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          onClick={(e) => handleSubmit3(e, item?._id)}
                          className="text-white border-danger bg-danger border"
                          style={{
                            fontSize: 10,
                            borderRadius: 3,
                            padding: "2px 8px",
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </>
                  )}
                  {(viewTypeData === "ctms" && StudyMode === "DV Mode") && (
                    <>
                      <td className="text-center">
                        <button
                          disabled={!(item?.queryStatus !== "open" &&
                            item?.reviewStatus === "pending" &&
                            StudyMode === "DV Mode")}
                          onClick={() => setIdReviewRowTable(item?.studyUniqueId)}
                          className="text-white border-success bg-success border"
                          style={{
                            fontSize: 10,
                            borderRadius: 3,
                            padding: "2px 8px",
                          }}
                        >
                          Signature
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          disabled={!(item?.queryStatus !== "open" &&
                            item?.reviewStatus === "pending" &&
                            StudyMode === "DV Mode")}
                          onClick={(e) => seIdGenerateQuery(item?.studyUniqueId)}
                          className="text-white border-success bg-success border"
                          style={{
                            fontSize: 10,
                            borderRadius: 3,
                            padding: "2px 8px",
                          }}
                        >
                          Generate Query
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="position-sticky bottom-0">
            {totalItems > 0 && (
              <Pagination
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            )}
          </div>
        </>
      </SplitPane>
    </>
  );
};

export default StudySiteTableData;
