import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import Pagination from "../../../../ETMF/Pagination/Pagination";

const ValueLabelTableData = ({
  valueLaveldata,
  tableRef,
  onMouseDown,
  getValueLevelData,
  studyId,
  setIsFormOpen,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const deleteStudyValueLevelData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Value lavel!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let results = await cdiscServices.deleteStudyValueLevelData(
          id,
          studyId
        );
        if (results?.statusCode === 200) {
          getValueLevelData();
        }
      }
    });
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = valueLaveldata?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(valueLaveldata?.length / itemsPerPage);
  return (
    <>
      <div className="overflow-auto">
        <Table
          ref={tableRef}
          id="resizable-table"
          className="text-nowrap custom-table tableLibrary"
          hover
          striped
        >
          <thead className="position-sticky z-1" style={{ top: -1 }}>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)}>Order</th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Dataset</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Variable</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Where Clause</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Data Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Length</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Significant</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>Format</th>
              <th onMouseDown={(e) => onMouseDown(e, 8)}>Mandatory</th>
              <th onMouseDown={(e) => onMouseDown(e, 9)}>Codelist</th>
              <th onMouseDown={(e) => onMouseDown(e, 10)}>Origin</th>
              <th onMouseDown={(e) => onMouseDown(e, 11)}>Pages</th>
              <th onMouseDown={(e) => onMouseDown(e, 12)}>Method</th>
              <th onMouseDown={(e) => onMouseDown(e, 13)}>Predecessor</th>
              <th onMouseDown={(e) => onMouseDown(e, 14)}>
                Value Level Comment
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 15)}>Join Comment</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 16)}
                style={{ width: 70, right: 50 }}
                className="text-center position-sticky"
              >
                Update
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 17)}
                style={{ width: 70, right: -1 }}
                className="text-center position-sticky"
              >
                Delete
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {result?.map((item, index) => (
              <tr
                key={index}
                className={`position-relative CP ${
                  highlightedRow === index && "activeMenu"
                }`}
                onClick={() => {
                  setHighlightedRow(index);
                }}
              >
                <td>{item.order}</td>
                <td>{item.domain}</td>
                <td>{item.domainValueLevelVeriableName}</td>
                <td>{item.domainWhereclauseVeriableName}</td>
                <td className="text-capitalize">{item.dataType}</td>
                <td>{item.length}</td>
                <td>{item.significant}</td>
                <td>{item.format}</td>
                <td className="text-capitalize">{item.mandatory}</td>
                <td>{item.codeListData}</td>
                <td>{item.origin}</td>
                <td>{item.pages}</td>
                <td>{item.mathod}</td>
                <td>{item.predecessor}</td>
                <td>{item.comment}</td>
                <td>{item.joinComment}</td>
                <td  style={{ right: 50 }} className="position-sticky bg-light">
                  <button
                    onClick={() => setIsFormOpen(item)}
                    title="Update"
                    className="text-white border-success bg-success border-0"
                    style={{ fontSize: 10, borderRadius: 3 }}
                  >
                    Update
                  </button>
                </td>
                <td style={{ right: -1 }} className="position-sticky bg-light">
                  <button
                    className="text-white border-danger bg-danger border-0"
                    style={{ fontSize: 10, borderRadius: 3 }}
                    title="Delete Supplementary"
                    onClick={() => deleteStudyValueLevelData(item?._id)}
                  >
                    Delete
                  </button>
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={valueLaveldata?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default ValueLabelTableData;
