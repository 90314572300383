import React, { useEffect, useRef, useState } from "react";
import { Form, Spinner, Table } from "react-bootstrap";
import SplitPane from "react-split-pane";
import Swal from "sweetalert2";
import { FaPlusSquare } from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import UseToggle from "../../../../Hooks/UseToggle";
import LegacyPopup from "./LegacyPopup";
import { cdiscServices } from "../../../../Services/CDISC/cdiscServices";
import { ContextMenuTrigger } from "react-contextmenu";
import RightClick from "../../../LeftComponent/RightClick";
import { PiSortAscendingLight, PiSortDescendingLight } from "react-icons/pi";
import { LiaSortSolid } from "react-icons/lia";
import { MdOutlineFilterAlt } from "react-icons/md";
import AddFilter from "./AddFilter";
import Pagination from "../../../ETMF/Pagination/Pagination";

const DatasetTable = ({
  studyId,
  uniqueId,
  isDataFormOpen,
  setMode,
  mode,
  setIsDataFormOpen,
  viewType,
  onMouseDown,
  tableRef,
  dataEntrystatus,
  studyDataTemplateRow,
  setStudyDataTemplateRow,
  studyDataTemplateColumn,
  loading,
  getStudyDataTemplateEntryData,
  showTemplateColumn,
  setLoading
}) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle("");
  const [selectFormat, setSelectFormat] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [editableRow, setEditableRow] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const [formValues, setFormValues] = useState({});
  const [type, setType] = useState("");
  const ref = useRef(null);

  const hours = Array.from({ length: 24 }, (_, i) => i + 1);
  const minuteSecond = Array.from({ length: 60 }, (_, i) => i + 1);
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "UNK", 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1969 }, (_, i) => 1970 + i).reverse();


  useEffect(() => {
    getStudyDataTemplateEntryData(studyId, uniqueId);
  }, [studyId, uniqueId]);

  const deleteStudyDataTemplateEntryData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Row!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.studyId = studyId;
        userData.uniqueId = uniqueId;
        userData.rowId = id;
        let results = await cdiscServices.deleteStudyDataTemplateEntryData(
          userData
        );
        if (results?.statusCode === 200) {
          getStudyDataTemplateEntryData(studyId, uniqueId);
        }
      }
    });
  };

  useEffect(() => {
    if (studyDataTemplateRow && studyDataTemplateRow[0]) {
      const initialFormValues = Object.keys(studyDataTemplateRow[0]).reduce(
        (acc, key) => {
          acc[key] = "";
          return acc;
        },
        {}
      );
      setFormValues(initialFormValues);
    }
  }, [studyDataTemplateRow]);

  const handleInputChange = (key, value) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [key]: value,
    }));
  };

  const handleTimeChange = (key, value, type, time) => {
    let dataArray = time?.split(":")
    let hourData = dataArray?.[0]
    let minuteData = dataArray?.[1]
    let secondData = dataArray?.[2]
    let finalValue = `${type === "hour" ? value : hourData}:${type === "minute" ? value : minuteData}:${type === "second" ? value : secondData}`
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [key]: finalValue,
    }));
  };

  const handleDateChange = (key, value, type, date) => {
    let dataArray = date?.split("-")
    let dayData = dataArray?.[0]
    let monthData = dataArray?.[1]
    let yearData = dataArray?.[2]
    let finalValue = `${type === "day" ? value : dayData}-${type === "month" ? value : monthData}-${type === "year" ? value : yearData}`
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [key]: finalValue,
    }));
  };

  const addDataTemplateEntryData = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.uniqueId = uniqueId;
    userData.rowData = formValues;
    let data = await cdiscServices.addDataTemplateEntryData(userData);
    if (data?.statusCode === 200) {
      getStudyDataTemplateEntryData(studyId, uniqueId);
    }
  };

  const updateStudyDataEntryData = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.uniqueId = uniqueId;
    userData.rowData = formValues;
    let data = await cdiscServices.updateStudyDataEntryData(userData);
    if (data?.statusCode === 200) {
      getStudyDataTemplateEntryData(studyId, uniqueId);
    }
  };

  const handleClickForUpdateData = async (uniqueId) => {
    setMode("update");
    setIsDataFormOpen(true);
    setFormValues(
      studyDataTemplateRow?.find((data) => data.uniqueId === uniqueId)
    );
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const csvType = file.type === "text/csv";
    const excelType =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (
      (selectFormat === "csv" && csvType) ||
      (selectFormat === "excel" && excelType)
    )
      setUploadFile(file);
    else {
      setUploadFile(null);
      alert(`Please select a ${selectFormat.toUpperCase()} file.`);
    }
  };

  const uploadFileinCRFRowData = async () => {
    if (uploadFile) {
      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("studyId", studyId);
      formData.append("uniqueId", uniqueId);
      let result = await cdiscServices.uploadFileinCRFRowData(formData);
      if (result?.statusCode === 200) {
        setUploadFile(null);
        getStudyDataTemplateEntryData(studyId, uniqueId);
      }
    }
  };

  const handleFormatChange = (format) => {
    setSelectFormat(format);
    setUploadFile(null);
  };

  const handleDoubleClick = (rowIndex, key) => {
    if (dataEntrystatus === "unLocked") {
      setEditableRow({ rowIndex, key });
    }
  };
  const [rowIndex, setRowIndex] = useState("");
  const [columnKey, setColumnKey] = useState("");
  const [updatedObject, setUpdatedObject] = useState("");
  const [fieldType, seFieldtType] = useState("");
  const handleDoubleClickPopup = (rowIndex, key, updatedObject) => {
    setRowIndex(rowIndex)
    setColumnKey(key)
    setUpdatedObject(updatedObject)
    seFieldtType(studyDataTemplateColumn?.find(item => item.name === key)?.type)
    if (dataEntrystatus === "unLocked") {
      setToggle();
    }
  };

  const [isChanged, setIsChanged] = useState(false);

  const handleChange = (e, rowIndex, key, updatedObject) => {
    const { value } = e.target;
    updatedObject[key] = value;
    setIsChanged(true);
    const updatedUserData = {
      ...updatedData,
      [rowIndex]: { ...updatedObject },
    };
    setUpdatedData(updatedUserData);
  };

  const handleBlur = async (e, updatedObject) => {
    if (isChanged) {
      const userData = {
        uniqueId: uniqueId,
        studyId: studyId,
        rowData: updatedObject,
      };
      const data = await cdiscServices.updateStudyDataEntryData(userData);
      if (data?.statusCode === 200) {
        getStudyDataTemplateEntryData(studyId, uniqueId);
      }
      setEditableRow(null);
      setIsChanged(false);
    } else {
      setEditableRow(null);
    };
  }

  const handleKeyPress = async (e, updatedObject) => {
    if (e.key === "Enter") {
      if (isChanged) {
        const userData = {
          uniqueId: uniqueId,
          studyId: studyId,
          rowData: updatedObject,
        };
        const data = await cdiscServices.updateStudyDataEntryData(userData);
        if (data?.statusCode === 200) {
          getStudyDataTemplateEntryData(studyId, uniqueId);
        }
        setEditableRow(null);
        setIsChanged(false);
      } else {
        setEditableRow(null);
      };
    }
  };

  const exportDataTemplateFileMultiFormat = async () => {
    let userData = {};
    userData.uniqueId = uniqueId;
    userData.studyId = studyId;
    await cdiscServices.exportDataTemplateFileMultiFormat(userData);
  };

  const sortColumnAscendingDescendingData = (key, order) => {
    setLoading(true);
    setTimeout(() => {
      let sortedData = studyDataTemplateRow.sort((a, b) => {
        if (order === 'asc') {
          return a[key] > b[key] ? 1 : -1;
        } else if (order === 'desc') {
          return a[key] < b[key] ? 1 : -1;
        }
      });

      setStudyDataTemplateRow([...sortedData]);
      setLoading(false);
    }, 200);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = studyDataTemplateRow?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(studyDataTemplateRow?.length / itemsPerPage);


  return (
    <>
      <SplitPane
        split="vertical"
        minSize={25}
        style={{ height: "89%" }}
        defaultSize={isDataFormOpen ? (dataEntrystatus === "locked" ? "100%" : "25%") : "100%"}
      >
        {isDataFormOpen && dataEntrystatus === "unLocked" && (
          <div className="p-2">
            {mode === "add" && (
              <Form.Group className="mb-2">
                <Form.Label className="mb-1 fw-bold text-capitalize">
                  Type
                </Form.Label>
                <Form.Select

                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="">Select Type</option>
                  <option selected={type === "manual"} value="manual">
                    Manual Entry
                  </option>
                  <option selected={type === "file"} value="file">
                    By File
                  </option>
                </Form.Select>
              </Form.Group>
            )}
            {(mode === "update" ? "manual" : type) === "manual" && (
              <>
                {mode === "add"
                  ? studyDataTemplateColumn.map((item, index) => (
                    <Form.Group key={index} className="mb-2">
                      <Form.Label className="mb-1 fw-bold text-capitalize">
                        {item?.name}
                      </Form.Label>
                      {item.type === "date" ?
                        <div className="hstack gap-2">
                          <Form.Group className="mb-2 position-relative">
                            <Form.Select
                              onChange={(e) => handleDateChange(item?.name, e.target.value, "day", formValues[item?.name])}
                            >
                              <option>Day</option>
                              <option selected={"UNK" === formValues[item?.name]?.split("-")?.[0]} value={"UNK"}>UNK</option>
                              {days.map((day) => (
                                <option selected={day === Number(formValues[item?.name]?.split("-")?.[0])} key={day} value={day}>
                                  {day}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-2 position-relative">
                            <Form.Select
                              onChange={(e) => handleDateChange(item?.name, e.target.value, "month", formValues[item?.name])}
                            >
                              <option>Month</option>
                              {months.map((month, index) => (
                                <option selected={month === formValues[item?.name]?.split("-")?.[1]} key={index} value={month}>
                                  {month}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-2 position-relative">
                            <Form.Select
                              onChange={(e) => handleDateChange(item?.name, e.target.value, "year", formValues[item?.name])}
                            >
                              <option>Year</option>
                              <option selected={"UNK" === formValues[item?.name]?.split("-")?.[2]} value={"UNK"}>UNK</option>
                              {years.map((year) => (
                                <option selected={year === Number(formValues[item?.name]?.split("-")?.[2])} key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                        : item.type === "time" ?
                          <div className="hstack gap-2">
                            <Form.Group className="mb-2 position-relative">
                              <Form.Select
                                onChange={(e) => handleTimeChange(item?.name, e.target.value, "hour", formValues[item?.name])}
                              >
                                <option>Hour</option>
                                <option selected={"UNK" === formValues[item?.name]?.split("-")?.[0]} value={"UNK"}>UNK</option>
                                {hours.map((hour) => (
                                  <option selected={hour === Number(formValues[item?.name]?.split("-")?.[0])} key={hour} value={hour}>
                                    {hour}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-2 position-relative">
                              <Form.Select
                                onChange={(e) => handleTimeChange(item?.name, e.target.value, "minute", formValues[item?.name])}
                              >
                                <option>Minute</option>
                                {minuteSecond.map((minute, index) => (
                                  <option selected={minute === formValues[item?.name]?.split("-")?.[1]} key={index} value={minute}>
                                    {minute}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-2 position-relative">
                              <Form.Select
                                onChange={(e) => handleTimeChange(item?.name, e.target.value, "second", formValues[item?.name])}
                              >
                                <option>Second</option>
                                <option selected={"UNK" === formValues[item?.name]?.split("-")?.[2]} value={"UNK"}>UNK</option>
                                {minuteSecond.map((second) => (
                                  <option selected={second === Number(formValues[item?.name]?.split("-")?.[2])} key={second} value={second}>
                                    {second}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </div>
                          :
                          <Form.Control
                            type={item?.type}
                            placeholder={`Enter ${item?.label}`}
                            value={formValues[item?.name]}
                            onChange={(e) =>
                              handleInputChange(item?.name, e.target.value)
                            }
                          />
                      }
                    </Form.Group>
                  ))
                  : mode === "update" &&
                  studyDataTemplateColumn.map((item, index) => (
                    <Form.Group key={index} className="mb-2">
                      <Form.Label className="mb-1 fw-bold text-capitalize">
                        {item?.name}
                      </Form.Label>
                      {item.type === "date" ?
                        <div className="hstack gap-2">
                          <Form.Group className="mb-2 position-relative">
                            <Form.Select
                              onChange={(e) => handleDateChange(item?.name, e.target.value, "day", formValues[item?.name])}
                            >
                              <option>Day</option>
                              <option selected={"UNK" === formValues[item?.name]?.split("-")?.[0]} value={"UNK"}>UNK</option>
                              {days.map((day) => (
                                <option selected={day === Number(formValues[item?.name]?.split("-")?.[0])} key={day} value={day}>
                                  {day}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-2 position-relative">
                            <Form.Select
                              onChange={(e) => handleDateChange(item?.name, e.target.value, "month", formValues[item?.name])}
                            >
                              <option>Month</option>
                              {months.map((month, index) => (
                                <option selected={month === formValues[item?.name]?.split("-")?.[1]} key={index} value={month}>
                                  {month}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-2 position-relative">
                            <Form.Select
                              onChange={(e) => handleDateChange(item?.name, e.target.value, "year", formValues[item?.name])}
                            >
                              <option>Year</option>
                              <option selected={"UNK" === formValues[item?.name]?.split("-")?.[2]} value={"UNK"}>UNK</option>
                              {years.map((year) => (
                                <option selected={year === Number(formValues[item?.name]?.split("-")?.[2])} key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                        : item.type === "time" ?
                          <div className="hstack gap-2">
                            <Form.Group className="mb-2 position-relative">
                              <Form.Select
                                onChange={(e) => handleTimeChange(item?.name, e.target.value, "hour", formValues[item?.name])}
                              >
                                <option>Select Hour</option>
                                <option selected={"UNK" === formValues[item?.name]?.split("-")?.[0]} value={"UNK"}>UNK</option>
                                {hours.map((hour) => (
                                  <option selected={hour === Number(formValues[item?.name]?.split("-")?.[0])} key={hour} value={hour}>
                                    {hour}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-2 position-relative">
                              <Form.Select
                                onChange={(e) => handleTimeChange(item?.name, e.target.value, "minute", formValues[item?.name])}
                              >
                                <option>Minute</option>
                                {minuteSecond.map((minute, index) => (
                                  <option selected={minute === formValues[item?.name]?.split("-")?.[1]} key={index} value={minute}>
                                    {minute}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-2 position-relative">
                              <Form.Select
                                onChange={(e) => handleTimeChange(item?.name, e.target.value, "second", formValues[item?.name])}
                              >
                                <option>Second</option>
                                <option selected={"UNK" === formValues[item?.name]?.split("-")?.[2]} value={"UNK"}>UNK</option>
                                {minuteSecond.map((second) => (
                                  <option selected={second === Number(formValues[item?.name]?.split("-")?.[2])} key={second} value={second}>
                                    {second}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </div>
                          :
                          <Form.Control
                            type={item?.type}
                            placeholder={`Enter ${item?.label}`}
                            value={formValues[item?.name]}
                            onChange={(e) =>
                              handleInputChange(item?.name, e.target.value)
                            }
                          />
                      }
                    </Form.Group>
                  ))}
                <Form.Group className="text-end">
                  {mode === "add" ? (
                    <button
                      onClick={addDataTemplateEntryData}
                      className="updateProfile"
                    >
                      <FaPlusSquare style={{ marginBottom: 2 }} /> Add Data
                    </button>
                  ) : (
                    <button
                      onClick={updateStudyDataEntryData}
                      className="updateProfile"
                    >
                      <FaPlusSquare style={{ marginBottom: 2 }} /> Update Data
                    </button>
                  )}
                </Form.Group>
              </>
            )}
            {type === "file" && mode === "add" && (
              <>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold">
                    Format <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select

                    aria-label="Default select example"
                    onChange={(e) => handleFormatChange(e.target.value)}
                    required
                  >
                    <option value="">Select Format</option>
                    <option selected={selectFormat === "csv"} value="csv">
                      CSV
                    </option>
                    <option selected={selectFormat === "excel"} value="excel">
                      Excel
                    </option>
                  </Form.Select>
                </Form.Group>
                {selectFormat && (
                  <Form.Group className="mb-1">
                    <Form.Label className="mb-1 fw-bold">
                      Upload Template <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control

                      type="file"
                      onChange={handleFileChange}
                      accept={selectFormat === "csv" ? ".csv" : ".xls, .xlsx"}
                      required
                    />
                    <div className="text-end">
                      <Form.Label
                        onClick={exportDataTemplateFileMultiFormat}
                        className="mb-0 fw-bold fs-10 cursor-pointer"
                        title="Download Template"
                      >
                        <LuDownload style={{ marginBottom: 2 }} /> Download CSV
                        Template
                      </Form.Label>
                    </div>
                  </Form.Group>
                )}
                <div className="text-end">
                  <button
                    onClick={
                      type === "file"
                        ? uploadFileinCRFRowData
                        : addDataTemplateEntryData
                    }
                    className="updateProfile"
                  >
                    <FaPlusSquare style={{ marginBottom: 2 }} /> Add Data
                  </button>
                </div>
              </>
            )}
          </div>
        )}
        {loading ? (
          <div className="hstack justify-content-center h-100 w-100 py-5">
            <Spinner />
          </div>
        ) : studyDataTemplateRow?.length === 0 ? (
          <div className="hstack justify-content-center h-100 w-100 py-5 fs-13">
            Datasets not Found
          </div>
        ) : (
          <div>
            <div className="overflow-auto">
              <Table
                ref={tableRef}
                className="m-0 custom-table tableLibrary"
                id="resizable-table"
                striped
                hover
              >
                <thead className="position-sticky" style={{ top: -1 }}>
                  <tr>
                    {studyDataTemplateColumn?.map((column, index) => (
                      showTemplateColumn.includes(column?.name) &&
                      <th key={index} onMouseDown={(e) => onMouseDown(e, index)}>
                        <ContextMenuTrigger
                          id={`crfData_${index}`}
                          collect={() => ({ id: index })}
                        >
                          {viewType === "name" ? column?.name : column?.label}
                        </ContextMenuTrigger>
                        <RightClick
                          ContextId={`crfData_${index}`}
                          Menu1
                          MenuName1={"Sort Ascending"}
                          icons1={
                            <PiSortAscendingLight
                              style={{ marginBottom: -3 }}
                            />
                          }
                          handleClick1={() => sortColumnAscendingDescendingData(column?.name, "asc")}
                          Menu2
                          MenuName2={"Sort Descending"}
                          icons2={
                            <PiSortDescendingLight
                              style={{ marginBottom: -3 }}
                            />
                          }
                          handleClick2={() => sortColumnAscendingDescendingData(column?.name, "desc")}
                          Menu3
                          MenuName3={"Sort by Data Order"}
                          icons3={
                            <LiaSortSolid
                              style={{ marginBottom: -3 }}
                            />
                          }
                          handleClick3={() => getStudyDataTemplateEntryData(studyId, uniqueId)}
                          Menu4
                          MenuName4={"Add Filter"}
                          icons4={
                            <MdOutlineFilterAlt
                              style={{ marginBottom: -3 }}
                            />
                          }
                          handleClick4={setToggle1}
                        />
                      </th>
                    ))}
                    {dataEntrystatus === "unLocked" &&
                      <>
                        <th className="text-center" style={{ width: 70 }}>
                          Update
                        </th>
                        <th className="text-center" style={{ width: 60 }}>
                          Delete
                        </th>
                      </>
                    }
                    <th></th>
                  </tr>
                </thead>
                <tbody ref={ref}>
                  {result?.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {Object.entries(row).map(([key, value], colIndex) =>
                        key === "uniqueId" ? null : (
                          showTemplateColumn.includes(key) &&
                          <td
                            className={`p-0 px-2 ${studyDataTemplateColumn?.find(item => item.name === key)?.check === "require" ? "bg-danger-subtle" : studyDataTemplateColumn?.find(item => item.name === key)?.check === "expected" ? "bg-success-subtle" : studyDataTemplateColumn?.find(item => item.name === key)?.check === "permissible" ? "" : ""} py-1`}
                            key={colIndex}
                            onDoubleClick={() =>
                              (studyDataTemplateColumn?.find(item => item.name === key)?.type === "date" || studyDataTemplateColumn?.find(item => item.name === key)?.type === "time")
                                ? handleDoubleClickPopup(rowIndex, key, row)
                                : handleDoubleClick(rowIndex, key)
                            }
                          >
                            {editableRow?.rowIndex === rowIndex &&
                              editableRow?.key === key ? (
                              <Form.Control
                                className="p-0 bg-transparent border-0 rounded-0"
                                style={{ width: "max-content" }}
                                type="text"
                                value={updatedData[rowIndex]?.[key] || value}
                                onChange={(e) =>
                                  handleChange(e, rowIndex, key, row)
                                }
                                onKeyPress={(e) => handleKeyPress(e, row)}
                                onBlur={(e) => handleBlur(e, row)}
                                autoFocus
                              />
                            ) : (
                              value
                            )}
                          </td>
                        )
                      )}
                      {dataEntrystatus === "unLocked" &&
                        <>
                          <td className="text-center">
                            <button
                              title="Update"
                              onClick={() => handleClickForUpdateData(row.uniqueId)}
                              className="text-white border-success bg-success border-0 fs-10 br3"
                              style={{ fontSize: 10, borderRadius: 3 }}
                            >
                              Update
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() =>
                                deleteStudyDataTemplateEntryData(row.uniqueId)
                              }
                              className="text-white border-danger bg-danger border-0 fs-10 br3"
                              style={{ fontSize: 10, borderRadius: 3 }}
                              title="Delete Data Template"
                            >
                              Delete
                            </button>
                          </td>
                        </>
                      }
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="position-sticky bottom-0">
              <Pagination
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={studyDataTemplateRow?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        )}
      </SplitPane>
      <AddFilter
        Show={!toggle1}
        Hide={setToggle1}
      />
      <LegacyPopup
        Show={!toggle}
        Hide={setToggle}
        Title={columnKey}
        studyId={studyId}
        rowIndex={rowIndex}
        columnKey={columnKey}
        updatedObject={updatedObject}
        updatedData={updatedData}
        setUpdatedData={setUpdatedData}
        getStudyDataTemplateEntryData={getStudyDataTemplateEntryData}
        fieldType={fieldType}
        uniqueId={uniqueId}
      />
    </>
  );
};

export default DatasetTable;
