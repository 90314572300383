import React, { useEffect, useRef, useState } from "react";
import { Form, Table } from "react-bootstrap";
import Pagination from "../../../ETMF/Pagination/Pagination";
import { SpinerDataLoader } from "../../../NoDataFoundTable/NoDataFoundTable";

const RandomizationTableData = ({ tableRef, onMouseDown, factorData, setOptions, setFactorName, loader, genderList }) => {
  const ref = useRef(null);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = factorData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(factorData?.length / itemsPerPage);

  return (
    <>
      {loader ?
        <SpinerDataLoader />
        : (result?.length === 0 && genderList?.length === 0) ? (
          <div className="hstack justify-content-center h-100" style={{ fontWeight: "bold" }}>
            Data not found!
          </div>
        ) : (
          <>
            <Table
              ref={tableRef}
              id="resizable-table"
              striped
              className="custom-table"
              hover
            >
              <thead className="thead-sticky">
                <tr>
                  <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                    Sr.
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>Name</th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>Lable</th>
                </tr>
              </thead>
              <tbody ref={ref}>
                {genderList?.length > 0 &&
                  <tr className="CP" onClick={() => {setOptions(genderList);  setFactorName("Gender")}}>
                    <td>0</td>
                    <td>gender</td>
                    <td>Gender</td>
                  </tr>
                }
                {result?.map((item, index, i) => (
                  <>
                    <tr className="CP" key={index}>
                      <td onClick={() => {setOptions(item.options); setFactorName(item.name)}}>{index + 1 + startIndex}</td>
                      <td onClick={() => {setOptions(item.options); setFactorName(item.name)}}>{item.columnValue}</td>
                      <td onClick={() => {setOptions(item.options); setFactorName(item.name)}}>{item.name}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0">
              <Pagination
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={factorData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </>
        )}
    </>
  );
};

export default RandomizationTableData;
