import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useParams } from "react-router";
import { clientLevelFormServices } from "../../../../../../../Services/formServices/clientLevelFormServices";
import { studyOnboardingService as studyVisitOnboardingService } from "../../../../../../../Services/studyServices/studyVisitsServices";
import { UseCrfViewContext } from "../../../../../../../Contexts/UseCrfViewContext";
import SplitPane from "react-split-pane";
import { NoDataFoundTable, SpinerDataLoader } from "../../../../../../NoDataFoundTable/NoDataFoundTable";

const SiteSubjectEvent = ({
  handleAddNewTab,
  subjectUniqueId,
  selectedStudySiteId,
  selectedStudySubjectId,
  studySubjectState,
  siteIdData,
  subjectStudyUniqueId
}) => {
  const { studyId } = useParams();

  const addCrfViewReportTabs = async (item) => {
    let data = await clientLevelFormServices.getClientLevelSingleFormData(item?.formUniqueId, studyId)
    let IdObject = {
      formName: data?.data?.name,
      formType: "initiateForm",
      siteName: selectedStudySiteId,
      subjectName: selectedStudySubjectId,
      visitName: item?.visitIdLabel,
      formUniqueId: item?.formUniqueId,
      visitFormType: data?.data?.formType,
      selectedStudyVisitsForm: data?.data?.form,
      subjectStudyUniqueId: item?.studyUniqueId
    };
    handleAddNewTab(
      `${data?.data?.name} Report`,
      "fa-solid fa-file",
      `formReportManegement_${item?._id}`,
      IdObject
    );
  };

  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const [studyDynamicColumn, setStudyDynamicColumn] = useState([]);
  const [studyDynamicRow, setStudyDynamicRow] = useState([]);
  const [studyDynamicColumnID, setStudyDynamicColumnID] = useState([]);

  const [loader, setLoader] = useState(true);

  const getStudyVisitColumnData = async () => {
    setLoader(false);
    let data = await studyVisitOnboardingService.getStudyVisitColumnData(studyId, subjectUniqueId);
    let initialData = data?.data === undefined ? [] : data?.data;
    if (initialData.length > 0) {
      let finalData = initialData?.sort()
      setStudyDynamicColumn(Object.values(finalData?.[0]?.column));
      setStudyDynamicColumnID(finalData?.[0]._id);
      setLoader(true);
    } else {
      setStudyDynamicColumn("");
      setStudyDynamicColumnID("");
      setLoader(true);
    }
  };

  const getStudyVisitRowDataBySubject = async () => {
    setLoader(false);
    let data = await studyVisitOnboardingService.getStudyVisitRowDataBySubject(
      studyId,
      subjectUniqueId,
      studyMode,
      studyDynamicColumnID,
      1,
      100
    );
    let initialData = Object.values(data.data === undefined ? "" : data?.data);
    let finalData = initialData?.sort();
    setStudyDynamicRow(finalData);
    setLoader(true);
  };

  useEffect(() => {
    getStudyVisitColumnData()
    if (subjectUniqueId) {
      getStudyVisitRowDataBySubject();
    }
  }, [studyDynamicColumnID, subjectUniqueId]);

  const {
    setQueryGenerateEndpoint,
    setGetTicketsEndPoint,
    setGetQueryListEndPoint,
    setReviewHistoryEndpoint,
    setFormFieldHistoryEndpoint,
    setReviewFormFieldEndpoint,
    setQueryReplyEndpoint,
    setQueryCommentEndpoint,
    setUpdateTicketStatusEndPoint,
    setDataEntryLockFreezeFormByIdEndPoint,
    setGetGlobalQueryStatusEndPoint,
    setDataEnteriesValueShow,
    setStudyDynamicForm,
  } = UseCrfViewContext();

  const addCrfViewTabs = (item) => {
    let IdObject = {
      dataEntryFormMode: 'initiateMode',
      formType: 'initiateVisitsForm',
      visitFormType: 'regular',
      studyUniqueId: item?.studyUniqueId,
      formUniqueId: item?.formUniqueId,
      selectedStudyVisitsInitiateId: item?.visitId,
      removeNevigation: false,
      subjectStudyUniqueId: subjectStudyUniqueId,
    }

    let idSubjectObject = {
      studySubjectState: studySubjectState,
      selectedStudySiteId: selectedStudySiteId,
      siteIdData: siteIdData,
      subjectStudyUniqueId: subjectStudyUniqueId,
    }

    let subjectBreadcrumb = [
      { tabName: `Site: ${selectedStudySiteId}`, currentTabName: `Study Site`, uniqueId: `studySite` },
      { tabName: `Subject List` }
    ]

    let idEventObject = {
      studySubjectIdOfVisitsState: subjectUniqueId,
      selectedStudySubjectId: selectedStudySubjectId,
      selectedStudySiteId: selectedStudySiteId,
      siteIdData: siteIdData,
      subjectStudyUniqueId: subjectStudyUniqueId,
    };

    let eventBreadcrumb = [
      { tabName: `Site: ${selectedStudySiteId}`, currentTabName: `Study Site`, uniqueId: `studySite` },
      { tabName: `Subject: ${selectedStudySubjectId}`, currentTabName: `${selectedStudySiteId} Subject`, uniqueId: `studySubject_${siteIdData}`, pageId: idSubjectObject, breadcrumb: subjectBreadcrumb },
      { tabName: `Subject Event List` }
    ]

    let breadcrumb = [
      { tabName: `Site: ${selectedStudySiteId}`, currentTabName: `Study Site`, uniqueId: `studySite` },
      { tabName: `Subject: ${selectedStudySubjectId}`, currentTabName: `${selectedStudySiteId} Subject`, uniqueId: `studySubject_${siteIdData}`, pageId: idSubjectObject, breadcrumb: subjectBreadcrumb },
      { tabName: `Event: ${item?.visitIdLabel}`, currentTabName: `${selectedStudySubjectId} Subject Event`, uniqueId: `subjectEventForm_${subjectStudyUniqueId}`, pageId: idEventObject, breadcrumb: eventBreadcrumb },
      { tabName: `Event Initiation: ${item?.visitIdLabel}` }
    ];

    handleAddNewTab(`${item?.visitIdLabel} Subject Events`, "fa-solid fa-form", `studyForm_${item?._id}`, IdObject, breadcrumb)
    getStudyVisitInitiateForm()
    setDataEntryLockFreezeFormByIdEndPoint(
      "getVisitsDataEntryLockFreezeFormByFormField"
    );
    setDataEnteriesValueShow("getdataEntryFormById");
    setQueryGenerateEndpoint("DataEntryQueryGenerator");
    setGetTicketsEndPoint("getDataEntryTickets");
    setGetQueryListEndPoint("getDataEntryQuery");
    setQueryReplyEndpoint("DataEntryQueryReply");
    setQueryCommentEndpoint("DataEntryQueryComment");
    setReviewHistoryEndpoint("getDataEntryFormFieldReviewHistoryByID");
    setReviewFormFieldEndpoint("reviewDataEntryFormField");
    setUpdateTicketStatusEndPoint("DataEntryUpdateTicketStatus");
    setGetGlobalQueryStatusEndPoint("getDataEntryTicketStatus");
    setFormFieldHistoryEndpoint("getDataEntryFormFieldValueHistoryById");
  };

  const getStudyVisitInitiateForm = async () => {
    let data;
    data = await clientLevelFormServices.getStudyLevelFormData(
      studyId,
      1,
      100
    );
    let finalData = data?.data?.sort().reverse();
    setStudyDynamicForm(finalData);
  };

  const viewStudyVisitForms = (item) => {
    let IdObject = {
      studyVisitFormStateId: item?.studyUniqueId,
      selectedStudyVisitsId: item?.visitIdLabel,
      subjectStudyUniqueId: subjectStudyUniqueId,
    }

    let idSubjectObject = {
      studySubjectState: studySubjectState,
      selectedStudySiteId: selectedStudySiteId,
      siteIdData: siteIdData,
      subjectStudyUniqueId: subjectStudyUniqueId,
    }

    let subjectBreadcrumb = [
      { tabName: `Site: ${selectedStudySiteId}`, currentTabName: `Study Site`, uniqueId: `studySite` },
      { tabName: `Subject List` }
    ]

    let idEventObject = {
      studySubjectIdOfVisitsState: subjectUniqueId,
      selectedStudySubjectId: selectedStudySubjectId,
      selectedStudySiteId: selectedStudySiteId,
      siteIdData: siteIdData,
      subjectStudyUniqueId: subjectStudyUniqueId,
    };

    let eventBreadcrumb = [
      { tabName: `Site: ${selectedStudySiteId}`, currentTabName: `Study Site`, uniqueId: `studySite` },
      { tabName: `Subject: ${selectedStudySubjectId}`, currentTabName: `${selectedStudySiteId} Subject`, uniqueId: `studySubject_${siteIdData}`, pageId: idSubjectObject, breadcrumb: subjectBreadcrumb },
      { tabName: `Subject Event List` }
    ]

    let breadcrumb = [
      { tabName: `Site: ${selectedStudySiteId}`, currentTabName: `Study Site`, uniqueId: `studySite` },
      { tabName: `Subject: ${selectedStudySubjectId}`, currentTabName: `${selectedStudySiteId} Subject`, uniqueId: `studySubject_${siteIdData}`, pageId: idSubjectObject, breadcrumb: subjectBreadcrumb },
      { tabName: `Event: ${item?.visitIdLabel}`, currentTabName: `${selectedStudySubjectId} Subject Event`, uniqueId: `subjectEventForm_${subjectStudyUniqueId}`, pageId: idEventObject, breadcrumb: eventBreadcrumb },
      { tabName: `Event Form List` }
    ];

    handleAddNewTab(`${item?.visitIdLabel} Subject Events Form`, "fa-solid fa-form", `ViewSubjectsEventForm_${item?._id}`, IdObject, breadcrumb)
  };

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "84%" }}
      >
        {!loader ?
          <SpinerDataLoader />
          : studyDynamicRow?.length === 0 ?
            <NoDataFoundTable MSG="Event not found" /> :
            <Table className="m-0 custom-table" hover striped>
              <thead className="position-sticky z-1" style={{ top: -1 }}>
                <tr>
                  <th style={{ width: 45 }}>S No.</th>
                  <th>Visit Id</th>
                  {studyDynamicColumn &&
                    studyDynamicColumn?.map(({ name }, i) => <th>{name}</th>)}
                  <th>Repeat</th>
                  <th>Visit Day</th>
                  <th>Time Point Id</th>
                  <th>Repeat Number</th>
                  <th>Initiate</th>
                  <th>Initiate Status</th>
                  <th>Status</th>
                  <th style={{ width: 240 }}>Initiation</th>
                  <th>Event Form</th>
                </tr>
              </thead>
              <tbody>
                {studyDynamicRow?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {index + 1}
                    </td>
                    <td>
                      {item.visitIdLabel}
                    </td>
                    {Object?.values(item?.row).map((data, index) => (
                      <td key={index}>
                        {data.valueData}
                      </td>
                    ))}
                    <td className="text-capitalize">
                      {item.repeat}
                    </td>
                    <td className="text-capitalize">
                      {item.visitDay}
                    </td>
                    <td>
                      {item.timePointId}
                    </td>
                    <td>
                      {item.repeatNumber || 0}
                    </td>
                    <td>
                      {item.initiate === "anyTime" ? "Any Time" : item.initiate === "afterFirstVisits" ? "After First Visit" : item.initiate}
                    </td>
                    <td>
                      {item?.initiated ? (
                        <div>Initiated</div>
                      ) : (
                        <div>Not Initiate</div>
                      )}
                    </td>
                    <td>
                      {item?.status ? "Completed" : "Ongoing"}
                    </td>
                    <td>
                      {item?.initiated ? (
                        <div className="d-flex gap-2">
                          <Button
                            onClick={(e) => {
                              addCrfViewTabs(item);
                            }}
                            style={{ fontSize: 10, borderRadius: 3, padding: "2px 8px" }}
                            className="text-white border-success bg-success border">
                            View Initiated Event
                          </Button>
                          <Button
                            onClick={(e) => {
                              addCrfViewReportTabs(item);
                            }}
                            style={{
                              fontSize: 10,
                              borderRadius: 3,
                              padding: "2px 8px",
                            }}
                            className="text-white border-primary bg-primary border"
                          >
                            View Initiated Report
                          </Button>
                        </div>
                      ) : (
                        <Button
                          disabled={!item?.formEnable}
                          onClick={(e) => {
                            addCrfViewTabs(item);
                          }}
                          style={{ fontSize: 10, borderRadius: 3, padding: "2px 8px" }}
                          className={`${item?.formEnable ? "text-muted border-warning bg-warning" : "text-muted border-secondary bg-light"}`}>
                          Initiate Event
                        </Button>
                      )}
                    </td>
                    <td>
                      <Button
                        disabled={!item?.initiated}
                        onClick={(e) => viewStudyVisitForms(item)}
                        style={{ fontSize: 10, borderRadius: 3, padding: "2px 8px" }}
                        className={item?.initiated ? "text-white border-success bg-success border" : "text-muted border-secondary bg-light"}>
                        View Event Form
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
        }
      </SplitPane>
    </>
  );
};

export default SiteSubjectEvent;
