import React, { useEffect, useState } from "react";
import {
  FaCircleCheck,
  FaCircleXmark,
} from "react-icons/fa6";
import Moment from "react-moment";
import { useParams } from "react-router";
import { Table, Offcanvas } from "react-bootstrap";
import SplitPane from "react-split-pane";
import { NoDataFoundTable, SpinerDataLoader } from "../../../NoDataFoundTable/NoDataFoundTable";
import Pagination from "../../../ETMF/Pagination/Pagination";
import FilterAuditTrailData from "./FilterAuditTrailData";
import { randomizerServices } from "../../../../Services/IWRS/randomizerServices";

const AuditTrial = () => {
  const { studyId } = useParams();
  const [filtered, setFiltered] = useState(false);
  const [recentActivity, setRecentActivity] = useState([]);
  const [activityUser, setActivityUser] = useState([]);
  const [activityCategory, setActivityCategory] = useState([]);
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [activityBy, setActivityBy] = useState("");
  const [status, setStatus] = useState("");
  const [tableLoader, setTableLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const getRandomizerActivity = async () => {
    setTableLoader(true);
    let data = await randomizerServices.getRandomizerActivity(
      studyId,
      studyMode
    );
    setRecentActivity(data?.data || []);
    setTableLoader(false);
  };


  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = recentActivity?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(recentActivity?.length / itemsPerPage);
  const handleResetFilterData = () => {
    setCategory("");
    setType("");
    setActivityBy("");
    setStatus("");
    setFiltered(false);
    handleClose();
  };

  useEffect(() => {
    getRandomizerActivity();
  }, []);

  return (
    <>
      <div className="p-1 hstack justify-content-between border-bottom">
        <div className="hstack gap-2 toolbar">
          <i
            onClick={handleShow}
            title="Filter"
            className="fa-solid fa-filter CP"
          />
          {filtered && (
            <i
              onClick={() => handleResetFilterData()}
              title="Clear Filter"
              className="fa-solid fa-filter-circle-xmark CP red"
            />
          )}
        </div>
      </div>
      <SplitPane
        split="vertical"
        className="position-relative"
        minSize={20}
        defaultSize="100%"
        style={{ height: "calc(100vh - 187px)", overflowX: "hidden" }}
      >
        {tableLoader ? (
          <SpinerDataLoader />
        ) : activityUser?.length > 0 || result?.length > 0 ? (
          <div>
            <Table
              id="resizable-table"
              striped
              className="custom-table"
              style={{ tableLayout: "auto" }}
            >
              <thead className="fixed_property_header">
                <tr>
                  <th style={{ width: 40 }} className="text-center">Sr.</th>
                  <th className="text-center">Status</th>
                  <th>Type</th>
                  <th>Activities</th>
                  <th>Category</th>
                  <th>Mode</th>
                  <th>Activity By</th>
                  <th>Activity Date</th>
                  <th>Activity Time</th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr className="relative">
                    <td className="text-center">{index + 1}.</td>
                    <td
                      title={item.status === "200" ? "Success" : "Failed"}
                      className="text-center"
                    >
                      {item.status === "200" ? (
                        <FaCircleCheck className="text-success" />
                      ) : (
                        <FaCircleXmark className="text-danger" />
                      )}
                    </td>
                    <td>
                      {item.type}
                    </td>
                    <td>{item.activities}</td>
                    <td className="text-capitalize">
                      {item.category}
                    </td>
                    <td>{item.mode}</td>
                    <td title={item.activityBy}>{item.activityBy}</td>
                    <td>
                      <Moment format="DD MMM YYYY">{item?.createdAt}</Moment>
                    </td>
                    <td>
                      <Moment format="HH:mm:ss">{item?.createdAt}</Moment>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0">
              <Pagination
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPage={totalPage}
                totalItems={recentActivity?.length}
              />
            </div>
          </div>
        ) : (
          <NoDataFoundTable MSG="Logs not found!" />
        )}
      </SplitPane>
    </>
  );
};

export default AuditTrial;
