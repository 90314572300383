import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { studyOnboardingService } from "../../../../../Services/studyServices/studySubjectsServices";
import { useParams } from "react-router";

const LabFormValues = ({
  labForm = [],
  item,
  handleLabFormChange,
  labFormDataEntry,
  handleCustomLabFormChange,
  valueData,
  disabledData,
  regularForm,
  tableRef,
  onMouseDown,
  formUniqueId,
  uniqueId,
  gender,
  visitUniqueId,
  setLabFormDataEntry
}) => {
  // const [customUnit, setCustomUnit] = useState("");
  const [addMoreRow, setAddMorerow] = useState([]);
  // const [labFormRange, setLabFormRange] = useState([]);
  const { studyId } = useParams()
  const getLabRangeSettingForm = async (formUniqueId, uniqueId) => {
    let data = await studyOnboardingService.getLabRangeSettingForm(studyId, formUniqueId, uniqueId, visitUniqueId)
    let labRange = data?.data || []
    let finalData = labRange?.map(item => {
      return {
        LBORREPARAMETER: "",
        LBORRES: "",
        LBORRESU: "",
        LBORNRLO: item?.lowerRange,
        LBORNRHI: item?.upperRange,
        LBORNRIND: "",
        LBCLSIG: "",
        LBCOM: ""
      };
    });
    // setLabFormRange(labRange || [])
    setLabFormDataEntry(finalData || [])
  }

  const handleAdd = () => {
    const newId = addMoreRow.length > 0 ? addMoreRow[addMoreRow.length - 1].id + 1 : labForm?.length + 1;
    let newObject = {}
    newObject.id = newId
    if (labForm?.some((item) => item.labTestCategory === "yes")) {
      newObject.labTestCategory = ""
      newObject.labTestCategoryName = ""
    }
    newObject.labTestName = ""
    newObject.labTestResultDataType = ""
    if (labForm?.some((item) => item.labTestSubCategory === "yes")) {
      newObject.labTestSubCategory = ""
      newObject.labTestSubCategoryName = ""
    }
    setAddMorerow([...addMoreRow, newObject]);
  };

  const handleRemove = (id) => {
    const updatedData = addMoreRow.filter((item) => item.id !== id);
    setAddMorerow(updatedData);
  };

  let newObjectData = { ...item }
  if (addMoreRow?.length > 0) {
    newObjectData.labForm = [...item?.labForm, ...addMoreRow]
  } else {
    newObjectData = newObjectData
  }

  useEffect(() => {
    if (labForm?.length < valueData?.length) {
      setAddMorerow(valueData?.slice(labForm?.length));
    }
  }, [valueData]);
  useEffect(() => {
    getLabRangeSettingForm(formUniqueId, uniqueId)
  }, [formUniqueId, uniqueId]);

  return (
    <>
      {!disabledData && !regularForm &&
        <div className="text-start">
          <button onClick={handleAdd} className="updateProfile"> + Add Custom Row</button>
        </div>
      }
      <div className="overflow-auto">
        <Table
          bordered
          ref={tableRef}
          className="custom-table tableLibrary border-start border-end mt-1"
        >
          <thead>
            <tr style={{ verticalAlign: "bottom" }}>
              {addMoreRow?.length > 0 && !disabledData &&
                <th rowSpan={2} onMouseDown={(e) => onMouseDown(e, 0)} className="position-sticky start-0" style={{ width: 50 }}>
                  <Form.Label className="m-0">
                    Remove
                  </Form.Label>
                </th>
              }
              <th rowSpan={2} onMouseDown={(e) => onMouseDown(e, addMoreRow?.length > 0 ? 1 : 0)} className="position-sticky" style={{ left: addMoreRow?.length > 0 ? 56.5 : 0 }}>
                <Form.Label className="m-0">
                  Examination Test or Examination Name
                </Form.Label>
              </th>
              {item?.testCategory === "yes" && (
                <th className="" rowSpan={2} onMouseDown={(e) => onMouseDown(e, addMoreRow?.length > 0 ? 2 : 1)}>
                  <Form.Label className="m-0">Category for Lab Test</Form.Label>
                </th>
              )}
              {item?.testSubCategory === "yes" && (
                <th className="" rowSpan={2} onMouseDown={(e) => onMouseDown(e, addMoreRow?.length > 0 ? 3 : item?.testCategory === "yes" ? 3 : 2)}>
                  <Form.Label className="m-0">
                    Subcategory for Lab Test
                  </Form.Label>
                </th>
              )}
              <th className="text-center" rowSpan={2} style={{ width: 85 }} onMouseDown={(e) => onMouseDown(e, addMoreRow?.length > 0 ? 4 : item?.testCategory === "yes" ? 4 : item?.testSubCategory === "yes" ? 4 : 1)}>
                <Form.Label className="m-0">Result</Form.Label>
              </th>
              <th className="text-center" rowSpan={2} style={{ width: 105 }} onMouseDown={(e) => onMouseDown(e, addMoreRow?.length > 0 ? 5 : item?.testCategory === "yes" ? 5 : item?.testSubCategory === "yes" ? 5 : 2)}>
                <Form.Label className="m-0">Units</Form.Label>
              </th>
              <th className="text-center" colSpan={2} onMouseDown={(e) => onMouseDown(e, addMoreRow?.length > 0 ? 6 : item?.testCategory === "yes" ? 6 : item?.testSubCategory === "yes" ? 6 : 3)}>
                <Form.Label className="m-0">Reference Range Indicator</Form.Label>
              </th>
              <th className="text-center" colSpan={2} onMouseDown={(e) => onMouseDown(e, addMoreRow?.length > 0 ? 7 : item?.testCategory === "yes" ? 7 : item?.testSubCategory === "yes" ? 7 : 4)}>
                <Form.Label className="m-0">Clinical Significance</Form.Label>
              </th>
              <th className="" rowSpan={2} style={{ width: 85 }} onMouseDown={(e) => onMouseDown(e, addMoreRow?.length > 0 ? 8 : item?.testCategory === "yes" ? 8 : item?.testSubCategory === "yes" ? 8 : 5)}>
                <Form.Label className="m-0">Remark &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Form.Label>
              </th>
              <th rowSpan={2} onMouseDown={(e) => onMouseDown(e, 9)}></th>
            </tr>
            <tr>
              <td className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
                <Form.Label className="m-0">Normal</Form.Label>
              </td>
              <td className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
                <Form.Label className="m-0">Abnormal</Form.Label>
              </td>
              <td className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
                <Form.Label className="m-0">CS</Form.Label>
              </td>
              <td className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
                <Form.Label className="m-0">NCS</Form.Label>
              </td>
            </tr>
          </thead>
          <tbody style={{ whiteSpace: "nowrap" }}>
            {labForm?.map((row, key) => (
              <tr key={key} className="text-center">
                {addMoreRow?.length > 0 && !disabledData &&
                  <td className="position-sticky start-0 bg-white" style={{ width: 50 }}></td>
                }
                <td className="position-sticky bg-white" style={{ left: addMoreRow?.length > 0 ? 56.5 : 0 }}>
                  <Form.Label className="m-0">{row.labTestName}</Form.Label>
                </td>
                {item?.testCategory === "yes" && (
                  <td>
                    <Form.Label className="m-0">
                      {row.labTestCategoryName}
                    </Form.Label>
                  </td>
                )}
                {item?.testSubCategory === "yes" && (
                  <td>
                    <Form.Label className="m-0">
                      {row.labTestSubCategoryName}
                    </Form.Label>
                  </td>
                )}
                <td
                  className="p-0"
                  style={{ background: (disabledData && !!valueData?.[key]?.LBORRES) && "#e9ecef" }}
                >
                  <div className="hstack justify-content-center">
                    <Form.Select
                      disabled={disabledData && !!valueData?.[key]?.LBORREPARAMETER}
                      onChange={(e) =>
                        handleLabFormChange(
                          "LBORREPARAMETER",
                          e.target.value,
                          "LBORRES",
                          labFormDataEntry?.[key]?.LBORRES || valueData?.[key]?.LBORRES,
                          "LBORRESU",
                          labFormDataEntry?.[key]?.LBORRESU || valueData?.[key]?.LBORRESU,
                          "LBORNRLO",
                          labFormDataEntry?.[key]?.LBORNRLO || valueData?.[key]?.LBORNRLO,
                          "LBORNRHI",
                          labFormDataEntry?.[key]?.LBORNRHI || valueData?.[key]?.LBORNRHI,
                          "LBORNRIND",
                          labFormDataEntry?.[key]?.LBORNRIND || valueData?.[key]?.LBORNRIND,
                          "LBCLSIG",
                          labFormDataEntry?.[key]?.LBCLSIG || valueData?.[key]?.LBCLSIG,
                          "LBCOM",
                          labFormDataEntry?.[key]?.LBCOM || valueData?.[key]?.LBCOM,
                          key,
                          newObjectData,
                          valueData,
                          (disabledData && !!valueData?.[key]?.LBORREPARAMETER)
                        )
                      }
                      className="border-0 rounded-0 bg-transparent border-end"
                      style={{ width: "max-content" }}
                    >
                      <option selected={
                        (labFormDataEntry?.[key]?.LBORREPARAMETER ||
                          valueData?.[key]?.LBORREPARAMETER) === "="
                      }
                        value="=">=</option>
                      <option selected={
                        (labFormDataEntry?.[key]?.LBORREPARAMETER ||
                          valueData?.[key]?.LBORREPARAMETER) === "+"
                      }
                        value="+">+</option>
                      <option selected={
                        (labFormDataEntry?.[key]?.LBORREPARAMETER ||
                          valueData?.[key]?.LBORREPARAMETER) === "-"
                      }
                        value="-">-</option>
                      <option selected={
                        (labFormDataEntry?.[key]?.LBORREPARAMETER ||
                          valueData?.[key]?.LBORREPARAMETER) === "≥"
                      }
                        value="≥">≥</option>
                      <option selected={
                        (labFormDataEntry?.[key]?.LBORREPARAMETER ||
                          valueData?.[key]?.LBORREPARAMETER) === "≤"
                      }
                        value="≤">≤</option>
                      <option selected={
                        (labFormDataEntry?.[key]?.LBORREPARAMETER ||
                          valueData?.[key]?.LBORREPARAMETER) === ">"
                      }
                        value=">">{`>`}</option>
                      <option selected={
                        (labFormDataEntry?.[key]?.LBORREPARAMETER ||
                          valueData?.[key]?.LBORREPARAMETER) === "<"
                      }
                        value="<">{`<`}</option>
                    </Form.Select>
                    <Form.Control
                      className="border-0 text-center w-100"
                      placeholder="Result"
                      style={{ minWidth: "max-content" }}
                      disabled={disabledData && !!valueData?.[key]?.LBORRES}
                      onChange={(e) =>
                        handleLabFormChange(
                          "LBORREPARAMETER",
                          labFormDataEntry?.[key]?.LBORREPARAMETER || labFormDataEntry?.[key]?.LBORREPARAMETER,
                          "LBORRES",
                          e.target.value,
                          "LBORRESU",
                          row?.options?.length > 1 ? (labFormDataEntry?.[key]?.LBORRESU || valueData?.[key]?.LBORRESU) : (row?.options?.[0]?.value || ""),
                          "LBORNRLO",
                          labFormDataEntry?.[key]?.LBORNRLO || valueData?.[key]?.LBORNRLO,
                          "LBORNRHI",
                          labFormDataEntry?.[key]?.LBORNRHI || valueData?.[key]?.LBORNRHI,
                          "LBORNRIND",
                          row?.options?.length > 1 ? (labFormDataEntry?.[key]?.LBORNRIND || valueData?.[key]?.LBORNRIND) : (row?.options?.[0]?.value || ""),
                          "LBCLSIG",
                          labFormDataEntry?.[key]?.LBCLSIG || valueData?.[key]?.LBCLSIG,
                          "LBCOM",
                          labFormDataEntry?.[key]?.LBCOM || valueData?.[key]?.LBCOM,
                          key,
                          newObjectData,
                          valueData,
                          (disabledData && !!valueData?.[key]?.LBORRES)
                        )
                      }
                      value={
                        labFormDataEntry?.[key]?.LBORRES ||
                        valueData?.[key]?.LBORRES
                      }
                      type={row.labTestResultDataType || "text"}
                    />
                  </div>
                </td>
                <td
                  className="p-0"
                  style={{ background: ((disabledData && !!valueData?.[key]?.LBORRESU) || (row?.options?.length <= 1)) && "#e9ecef" }}
                >
                  {row?.options?.length > 1 ?
                    <Form.Select
                      className="border-0 w-100"
                      disabled={disabledData && !!valueData?.[key]?.LBORRESU}
                      style={{ minWidth: "max-content" }}
                      onChange={(e) => {
                        handleLabFormChange(
                          "LBORREPARAMETER",
                          labFormDataEntry?.[key]?.LBORREPARAMETER || valueData?.[key]?.LBORREPARAMETER,
                          "LBORRES",
                          labFormDataEntry?.[key]?.LBORRES || valueData?.[key]?.LBORRES,
                          "LBORRESU",
                          e.target.value,
                          "LBORNRLO",
                          labFormDataEntry?.[key]?.LBORNRLO || valueData?.[key]?.LBORNRLO,
                          "LBORNRHI",
                          labFormDataEntry?.[key]?.LBORNRHI || valueData?.[key]?.LBORNRHI,
                          "LBORNRIND",
                          labFormDataEntry?.[key]?.LBORNRIND || valueData?.[key]?.LBORNRIND,
                          "LBCLSIG",
                          labFormDataEntry?.[key]?.LBCLSIG || valueData?.[key]?.LBCLSIG,
                          "LBCOM",
                          labFormDataEntry?.[key]?.LBCOM || valueData?.[key]?.LBCOM,
                          key,
                          newObjectData,
                          valueData,
                          (disabledData && !!valueData?.[key]?.LBORRESU)
                        )
                      }}
                    >
                      <option>Select Unit</option>
                      {row?.options?.map((option, i) => (
                        <option
                          selected={
                            (labFormDataEntry?.[key]?.LBORRESU ||
                              valueData?.[key]?.LBORRESU) === option.value
                          }
                          key={i}
                          value={option.value}
                        >
                          {option.value}
                        </option>
                      ))}
                    </Form.Select>
                    :
                    <Form.Control
                      disabled
                      style={{ width: "max-content" }}
                      className="border-0 text-center"
                      placeholder={row?.options?.length === 0 ? "No Unit" : "Unit"}
                      onMouseOver={(e) =>
                        handleLabFormChange(
                          "LBORREPARAMETER",
                          labFormDataEntry?.[key]?.LBORREPARAMETER || valueData?.[key]?.LBORREPARAMETER,
                          "LBORRES",
                          labFormDataEntry?.[key]?.LBORRES || valueData?.[key]?.LBORRES,
                          "LBORRESU",
                          row?.options?.[0]?.value || "",
                          "LBORNRLO",
                          labFormDataEntry?.[key]?.LBORNRLO || valueData?.[key]?.LBORNRLO,
                          "LBORNRHI",
                          labFormDataEntry?.[key]?.LBORNRHI || valueData?.[key]?.LBORNRHI,
                          "LBORNRIND",
                          labFormDataEntry?.[key]?.LBORNRIND || valueData?.[key]?.LBORNRIND,
                          "LBCLSIG",
                          labFormDataEntry?.[key]?.LBCLSIG || valueData?.[key]?.LBCLSIG,
                          "LBCOM",
                          labFormDataEntry?.[key]?.LBCOM || valueData?.[key]?.LBCOM,
                          key,
                          newObjectData,
                          valueData,
                          (disabledData && !!valueData?.[key]?.LBORRESU)
                        )
                      }
                      value={row?.options?.[0]?.value}
                      type={"text"}
                    />
                  }
                </td>
                {/* <td
                  className="p-0"
                  style={{ background: disabledData && "#e9ecef" }}
                >
                  <Form.Control
                    disabled={disabledData}
                    className="border-0 text-center"
                    placeholder="Lower Limit"
                    onChange={(e) =>
                      handleLabFormChange(
                        "LBORREPARAMETER",
                        labFormDataEntry?.[key]?.LBORREPARAMETER || valueData?.[key]?.LBORREPARAMETER,
                        "LBORRES",
                        labFormDataEntry?.[key]?.LBORRES || valueData?.[key]?.LBORRES,
                        "LBORRESU",
                        labFormDataEntry?.[key]?.LBORRESU || valueData?.[key]?.LBORRESU,
                        "LBORNRLO",
                        labFormRange?.find(range => range?.labTestName === row?.labTestName)?.lowerRange || e.target.value,
                        "LBORNRHI",
                        labFormDataEntry?.[key]?.LBORNRHI || valueData?.[key]?.LBORNRHI,
                        "LBORNRIND",
                        labFormDataEntry?.[key]?.LBORNRIND || valueData?.[key]?.LBORNRIND,
                        "LBCLSIG",
                        labFormDataEntry?.[key]?.LBCLSIG || valueData?.[key]?.LBCLSIG,
                        "LBCOM",
                        labFormDataEntry?.[key]?.LBCOM || valueData?.[key]?.LBCOM,
                        key,
                        newObjectData
                      )
                    }
                    value={
                      labFormRange?.find(range => range?.labTestName === row?.labTestName)?.lowerRange || labFormDataEntry?.[key]?.LBORNRLO ||
                      valueData?.[key]?.LBORNRLO
                    }
                    type={"text"}
                  />
                </td> */}
                {/* <td
                  className="p-0"
                  style={{ background: disabledData && "#e9ecef" }}
                >
                  <Form.Control
                    disabled={disabledData}
                    className="border-0 text-center"
                    placeholder="Upper Limit"
                    onChange={(e) =>
                      handleLabFormChange(
                        "LBORREPARAMETER",
                        labFormDataEntry?.[key]?.LBORREPARAMETER || valueData?.[key]?.LBORREPARAMETER,
                        "LBORRES",
                        labFormDataEntry?.[key]?.LBORRES || valueData?.[key]?.LBORRES,
                        "LBORRESU",
                        labFormDataEntry?.[key]?.LBORRESU || valueData?.[key]?.LBORRESU,
                        "LBORNRLO",
                        labFormDataEntry?.[key]?.LBORNRLO || valueData?.[key]?.LBORNRLO,
                        "LBORNRHI",
                        labFormRange?.find(range => range?.labTestName === row?.labTestName)?.upperRange || e.target.value,
                        "LBORNRIND",
                        labFormDataEntry?.[key]?.LBORNRIND || valueData?.[key]?.LBORNRIND,
                        "LBCLSIG",
                        labFormDataEntry?.[key]?.LBCLSIG || valueData?.[key]?.LBCLSIG,
                        "LBCOM",
                        labFormDataEntry?.[key]?.LBCOM || valueData?.[key]?.LBCOM,
                        key,
                        newObjectData
                      )
                    }
                    value={
                      labFormRange?.find(range => range?.labTestName === row?.labTestName)?.upperRange || labFormDataEntry?.[key]?.LBORNRHI ||
                      valueData?.[key]?.LBORNRHI
                    }
                    type={"text"}
                  />
                </td> */}
                <td className="text-center">
                  <Form.Check
                    disabled={disabledData && !!valueData?.[key]?.LBORNRIND}
                    className="rounded-0"
                    name={`labformnormalype_${key}`}
                    type="radio"
                    value="Normal"
                    onChange={(e) =>
                      handleLabFormChange(
                        "LBORREPARAMETER",
                        labFormDataEntry?.[key]?.LBORREPARAMETER || valueData?.[key]?.LBORREPARAMETER,
                        "LBORRES",
                        labFormDataEntry?.[key]?.LBORRES || valueData?.[key]?.LBORRES,
                        "LBORRESU",
                        labFormDataEntry?.[key]?.LBORRESU || valueData?.[key]?.LBORRESU,
                        "LBORNRLO",
                        labFormDataEntry?.[key]?.LBORNRLO || valueData?.[key]?.LBORNRLO,
                        "LBORNRHI",
                        labFormDataEntry?.[key]?.LBORNRHI || valueData?.[key]?.LBORNRHI,
                        "LBORNRIND",
                        e.target.value,
                        "LBCLSIG",
                        e.target.value === "Normal" ? "" : (labFormDataEntry?.[key]?.LBCLSIG || valueData?.[key]?.LBCLSIG),
                        "LBCOM",
                        labFormDataEntry?.[key]?.LBCOM || valueData?.[key]?.LBCOM,
                        key,
                        newObjectData,
                        valueData,
                        (disabledData && !!valueData?.[key]?.LBORNRIND)
                      )
                    }
                    checked={
                      (labFormDataEntry?.[key]?.LBORNRIND ||
                        valueData?.[key]?.LBORNRIND) === "Normal"
                    }
                  />
                </td>
                <td className="text-center">
                  <Form.Check
                    disabled={disabledData && !!valueData?.[key]?.LBORNRIND}
                    name={`labformnormalype_${key}`}
                    type="radio"
                    value="Abnormal"
                    onChange={(e) =>
                      handleLabFormChange(
                        "LBORREPARAMETER",
                        labFormDataEntry?.[key]?.LBORREPARAMETER || valueData?.[key]?.LBORREPARAMETER,
                        "LBORRES",
                        labFormDataEntry?.[key]?.LBORRES || valueData?.[key]?.LBORRES,
                        "LBORRESU",
                        labFormDataEntry?.[key]?.LBORRESU || valueData?.[key]?.LBORRESU,
                        "LBORNRLO",
                        labFormDataEntry?.[key]?.LBORNRLO || valueData?.[key]?.LBORNRLO,
                        "LBORNRHI",
                        labFormDataEntry?.[key]?.LBORNRHI || valueData?.[key]?.LBORNRHI,
                        "LBORNRIND",
                        e.target.value,
                        "LBCLSIG",
                        labFormDataEntry?.[key]?.LBCLSIG || valueData?.[key]?.LBCLSIG,
                        "LBCOM",
                        labFormDataEntry?.[key]?.LBCOM || valueData?.[key]?.LBCOM,
                        key,
                        newObjectData,
                        valueData,
                        (disabledData && !!valueData?.[key]?.LBORNRIND)
                      )
                    }
                    checked={
                      (labFormDataEntry?.[key]?.LBORNRIND ||
                        valueData?.[key]?.LBORNRIND) === "Abnormal"
                    }
                  />
                </td>
                <td className="text-center">
                  <Form.Label className="m-0">
                    <Form.Check
                      disabled={(disabledData && !!valueData?.[key]?.LBCLSIG) || (labFormDataEntry?.[key]?.LBORNRIND ||
                        valueData?.[key]?.LBORNRIND) === "Normal"}
                      name={`labformcstype_${key}`}
                      type="radio"
                      value="CS"
                      onChange={(e) =>
                        handleLabFormChange(
                          "LBORREPARAMETER",
                          labFormDataEntry?.[key]?.LBORREPARAMETER || valueData?.[key]?.LBORREPARAMETER,
                          "LBORRES",
                          labFormDataEntry?.[key]?.LBORRES || valueData?.[key]?.LBORRES,
                          "LBORRESU",
                          labFormDataEntry?.[key]?.LBORRESU || valueData?.[key]?.LBORRESU,
                          "LBORNRLO",
                          labFormDataEntry?.[key]?.LBORNRLO || valueData?.[key]?.LBORNRLO,
                          "LBORNRHI",
                          labFormDataEntry?.[key]?.LBORNRHI || valueData?.[key]?.LBORNRHI,
                          "LBORNRIND",
                          labFormDataEntry?.[key]?.LBORNRIND || valueData?.[key]?.LBORNRIND,
                          "LBCLSIG",
                          e.target.value,
                          "LBCOM",
                          labFormDataEntry?.[key]?.LBCOM || labFormDataEntry?.[key]?.LBCOM,
                          key,
                          newObjectData,
                          valueData,
                          (disabledData && !!valueData?.[key]?.LBCLSIG) || (labFormDataEntry?.[key]?.LBORNRIND ||
                            valueData?.[key]?.LBORNRIND) === "Normal"
                        )
                      }
                      checked={
                        (labFormDataEntry?.[key]?.LBCLSIG ||
                          valueData?.[key]?.LBCLSIG) === "CS"
                      }
                    />
                  </Form.Label>
                </td>
                <td className="text-center">
                  <Form.Label className="m-0">
                    <Form.Check
                      disabled={(disabledData && !!valueData?.[key]?.LBCLSIG) || (labFormDataEntry?.[key]?.LBORNRIND ||
                        valueData?.[key]?.LBORNRIND) === "Normal"}
                      name={`labformcstype_${key}`}
                      type="radio"
                      value={"NCS"}
                      onChange={(e) =>
                        handleLabFormChange(
                          "LBORREPARAMETER",
                          labFormDataEntry?.[key]?.LBORREPARAMETER || valueData?.[key]?.LBORREPARAMETER,
                          "LBORRES",
                          labFormDataEntry?.[key]?.LBORRES || valueData?.[key]?.LBORRES,
                          "LBORRESU",
                          labFormDataEntry?.[key]?.LBORRESU || valueData?.[key]?.LBORRESU,
                          "LBORNRLO",
                          labFormDataEntry?.[key]?.LBORNRLO || valueData?.[key]?.LBORNRLO,
                          "LBORNRHI",
                          labFormDataEntry?.[key]?.LBORNRHI || valueData?.[key]?.LBORNRHI,
                          "LBORNRIND",
                          labFormDataEntry?.[key]?.LBORNRIND || valueData?.[key]?.LBORNRIND,
                          "LBCLSIG",
                          e.target.value,
                          "LBCOM",
                          labFormDataEntry?.[key]?.LBCOM || valueData?.[key]?.LBCOM,
                          key,
                          newObjectData,
                          valueData,
                          (disabledData && !!valueData?.[key]?.LBCLSIG) || (labFormDataEntry?.[key]?.LBORNRIND ||
                            valueData?.[key]?.LBORNRIND) === "Normal"
                        )
                      }
                      checked={
                        (labFormDataEntry?.[key]?.LBCLSIG ||
                          valueData?.[key]?.LBCLSIG) === "NCS"
                      }
                    />
                  </Form.Label>
                </td>
                <td
                  className="p-0"
                  style={{ background: (disabledData && !!valueData?.[key]?.LBCOM) && "#e9ecef" }}
                >
                  <Form.Control
                    disabled={disabledData && !!valueData?.[key]?.LBCOM}
                    className="border-0 w-100 p-1"
                    placeholder="Remark"
                    style={{ resize: "none" }}
                    onChange={(e) =>
                      handleLabFormChange(
                        "LBORREPARAMETER",
                        labFormDataEntry?.[key]?.LBORREPARAMETER || valueData?.[key]?.LBORREPARAMETER,
                        "LBORRES",
                        labFormDataEntry?.[key]?.LBORRES || valueData?.[key]?.LBORRES,
                        "LBORRESU",
                        labFormDataEntry?.[key]?.LBORRESU || valueData?.[key]?.LBORRESU,
                        "LBORNRLO",
                        labFormDataEntry?.[key]?.LBORNRLO || valueData?.[key]?.LBORNRLO,
                        "LBORNRHI",
                        labFormDataEntry?.[key]?.LBORNRHI || valueData?.[key]?.LBORNRHI,
                        "LBORNRIND",
                        labFormDataEntry?.[key]?.LBORNRIND || valueData?.[key]?.LBORNRIND,
                        "LBCLSIG",
                        labFormDataEntry?.[key]?.LBCLSIG || valueData?.[key]?.LBCLSIG,
                        "LBCOM",
                        e.target.value,
                        key,
                        newObjectData,
                        valueData,
                        (disabledData && !!valueData?.[key]?.LBCOM)
                      )
                    }
                    value={
                      labFormDataEntry?.[key]?.LBCOM || valueData?.[key]?.LBCOM
                    }
                    as={"textarea"}
                  />
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
          <tbody style={{ whiteSpace: "nowrap" }}>
            {addMoreRow?.map((row, key) => (
              <tr key={key}>
                {addMoreRow?.length > 0 && !disabledData &&
                  <td
                    className="position-sticky start-0 bg-white text-center text-danger" style={{ width: 50 }}
                    onClick={() => handleRemove(row?.id)}
                  >
                    <FaTrashAlt style={{ fontSize: 11, cursor: "pointer" }} />
                  </td>
                }
                <td
                  className="position-sticky bg-white" style={{ background: (disabledData && !!valueData?.[key + labForm?.length]?.LBTESTNAME) && "#e9ecef", left: addMoreRow?.length > 0 ? 56.5 : 0 }}
                >
                  <Form.Control
                    disabled={disabledData && !!valueData?.[key + labForm?.length]?.LBTESTNAME}
                    className="border-0 px-1"
                    placeholder="Test name"
                    onChange={(e) =>
                      handleCustomLabFormChange(
                        "LBTESTNAME",
                        e.target.value,
                        "LBTESTCATEGORY",
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTCATEGORY || valueData?.[key + labForm?.length]?.LBTESTCATEGORY,
                        "LBTESTSUBCATEGORY",
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTSUBCATEGORY || valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY,
                        "LBORREPARAMETER",
                        labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER || valueData?.[key + labForm?.length]?.LBORREPARAMETER,
                        "LBORRES",
                        labFormDataEntry?.[key + labForm?.length]?.LBORRES || valueData?.[key + labForm?.length]?.LBORRES,
                        "LBORRESU",
                        labFormDataEntry?.[key + labForm?.length]?.LBORRESU || valueData?.[key + labForm?.length]?.LBORRESU,
                        "LBORNRLO",
                        labFormDataEntry?.[key + labForm?.length]?.LBORNRLO || valueData?.[key + labForm?.length]?.LBORNRLO,
                        "LBORNRHI",
                        labFormDataEntry?.[key + labForm?.length]?.LBORNRHI || valueData?.[key + labForm?.length]?.LBORNRHI,
                        "LBORNRIND",
                        labFormDataEntry?.[key + labForm?.length]?.LBORNRIND || valueData?.[key + labForm?.length]?.LBORNRIND,
                        "LBCLSIG",
                        labFormDataEntry?.[key + labForm?.length]?.LBCLSIG || valueData?.[key + labForm?.length]?.LBCLSIG,
                        "LBCOM",
                        labFormDataEntry?.[key + labForm?.length]?.LBCOM || valueData?.[key + labForm?.length]?.LBCOM,
                        key + labForm?.length,
                        newObjectData,
                        valueData,
                        disabledData && !!valueData?.[key + labForm?.length]?.LBTESTNAME
                      )
                    }
                    value={
                      labFormDataEntry?.[key + labForm?.length]?.LBTESTNAME ||
                      valueData?.[key + labForm?.length]?.LBTESTNAME
                    }
                    type={"text"}
                  />
                </td>
                {labForm?.some((item) => item.labTestCategory === "yes") && (
                  <td
                    className="p-0"
                    style={{ background: (disabledData && !!valueData?.[key + labForm?.length]?.LBTESTCATEGORY) && "#e9ecef" }}
                  >
                    <Form.Control
                      disabled={disabledData && !!valueData?.[key + labForm?.length]?.LBTESTCATEGORY}
                      className="border-0 px-1"
                      placeholder="Category Name"
                      onChange={(e) =>
                        handleCustomLabFormChange(
                          "LBTESTNAME",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTNAME || valueData?.[key + labForm?.length]?.LBTESTNAME,
                          "LBTESTCATEGORY",
                          e.target.value,
                          "LBTESTSUBCATEGORY",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTSUBCATEGORY || valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY,
                          "LBORREPARAMETER",
                          labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER || valueData?.[key + labForm?.length]?.LBORREPARAMETER,
                          "LBORRES",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRES || valueData?.[key + labForm?.length]?.LBORRES,
                          "LBORRESU",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRESU || valueData?.[key + labForm?.length]?.LBORRESU,
                          "LBORNRLO",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRLO || valueData?.[key + labForm?.length]?.LBORNRLO,
                          "LBORNRHI",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRHI || valueData?.[key + labForm?.length]?.LBORNRHI,
                          "LBORNRIND",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRIND || valueData?.[key + labForm?.length]?.LBORNRIND,
                          "LBCLSIG",
                          labFormDataEntry?.[key + labForm?.length]?.LBCLSIG || valueData?.[key + labForm?.length]?.LBCLSIG,
                          "LBCOM",
                          labFormDataEntry?.[key + labForm?.length]?.LBCOM || valueData?.[key + labForm?.length]?.LBCOM,
                          key + labForm?.length,
                          newObjectData,
                          valueData,
                          disabledData && !!valueData?.[key + labForm?.length]?.LBTESTCATEGORY
                        )
                      }
                      value={
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTCATEGORY ||
                        valueData?.[key + labForm?.length]?.LBTESTCATEGORY
                      }
                      type={"text"}
                    />
                  </td>
                )}
                {labForm?.some((item) => item.labTestSubCategory === "yes") && (
                  <td
                    className="p-0"
                    style={{ background: (disabledData && !!valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY) && "#e9ecef" }}
                  >
                    <Form.Control
                      disabled={disabledData && !!valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY}
                      className="border-0 px-1"
                      placeholder="Sub Category name"
                      onChange={(e) =>
                        handleCustomLabFormChange(
                          "LBTESTNAME",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTNAME || valueData?.[key + labForm?.length]?.LBTESTNAME,
                          "LBTESTCATEGORY",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTCATEGORY || valueData?.[key + labForm?.length]?.LBTESTCATEGORY,
                          "LBTESTSUBCATEGORY",
                          e.target.value,
                          "LBORREPARAMETER",
                          labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER || valueData?.[key + labForm?.length]?.LBORREPARAMETER,
                          "LBORRES",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRES || valueData?.[key + labForm?.length]?.LBORRES,
                          "LBORRESU",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRESU || valueData?.[key + labForm?.length]?.LBORRESU,
                          "LBORNRLO",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRLO || valueData?.[key + labForm?.length]?.LBORNRLO,
                          "LBORNRHI",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRHI || valueData?.[key + labForm?.length]?.LBORNRHI,
                          "LBORNRIND",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRIND || valueData?.[key + labForm?.length]?.LBORNRIND,
                          "LBCLSIG",
                          labFormDataEntry?.[key + labForm?.length]?.LBCLSIG || valueData?.[key + labForm?.length]?.LBCLSIG,
                          "LBCOM",
                          labFormDataEntry?.[key + labForm?.length]?.LBCOM || valueData?.[key + labForm?.length]?.LBCOM,
                          key + labForm?.length,
                          newObjectData,
                          valueData,
                          disabledData && !!valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY
                        )
                      }
                      value={
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTSUBCATEGORY ||
                        valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY
                      }
                      type={"text"}
                    />
                  </td>
                )}
                <td
                  className="p-0 text-center"
                  style={{ background: ((disabledData && !!valueData?.[key + labForm?.length]?.LBORREPARAMETER) && !!valueData?.[key + labForm?.length]?.LBORRES) && "#e9ecef" }}
                >
                  <div className="hstack justify-content-center">
                    <Form.Select
                      disabled={disabledData && !!valueData?.[key + labForm?.length]?.LBORREPARAMETER}
                      onChange={(e) =>
                        handleCustomLabFormChange(
                          "LBTESTNAME",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTNAME || valueData?.[key + labForm?.length]?.LBTESTNAME,
                          "LBTESTCATEGORY",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTCATEGORY || valueData?.[key + labForm?.length]?.LBTESTCATEGORY,
                          "LBTESTSUBCATEGORY",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTSUBCATEGORY || valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY,
                          "LBORREPARAMETER",
                          e.target.value,
                          "LBORRES",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRES || valueData?.[key + labForm?.length]?.LBORRES,
                          "LBORRESU",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRESU || valueData?.[key + labForm?.length]?.LBORRESU,
                          "LBORNRLO",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRLO || valueData?.[key + labForm?.length]?.LBORNRLO,
                          "LBORNRHI",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRHI || valueData?.[key + labForm?.length]?.LBORNRHI,
                          "LBORNRIND",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRIND || valueData?.[key + labForm?.length]?.LBORNRIND,
                          "LBCLSIG",
                          labFormDataEntry?.[key + labForm?.length]?.LBCLSIG || valueData?.[key + labForm?.length]?.LBCLSIG,
                          "LBCOM",
                          labFormDataEntry?.[key + labForm?.length]?.LBCOM || valueData?.[key + labForm?.length]?.LBCOM,
                          key + labForm?.length,
                          newObjectData,
                          valueData,
                          disabledData && !!valueData?.[key + labForm?.length]?.LBORREPARAMETER
                        )
                      }
                      className="border-0 rounded-0 bg-transparent border-end"
                      style={{ width: "max-content" }}
                    >
                      <option selected={
                        (labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER ||
                          valueData?.[key + labForm?.length]?.LBORREPARAMETER) === "="
                      }
                        value="=">=</option>
                      <option selected={
                        (labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER ||
                          valueData?.[key + labForm?.length]?.LBORREPARAMETER) === "+"
                      }
                        value="+">+</option>
                      <option selected={
                        (labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER ||
                          valueData?.[key + labForm?.length]?.LBORREPARAMETER) === "-"
                      }
                        value="-">-</option>
                      <option selected={
                        (labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER ||
                          valueData?.[key + labForm?.length]?.LBORREPARAMETER) === "≥"
                      }
                        value="≥">≥</option>
                      <option selected={
                        (labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER ||
                          valueData?.[key + labForm?.length]?.LBORREPARAMETER) === "≤"
                      }
                        value="≤">≤</option>
                      <option selected={
                        (labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER ||
                          valueData?.[key + labForm?.length]?.LBORREPARAMETER) === ">"
                      }
                        value=">">{`>`}</option>
                      <option selected={
                        (labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER ||
                          valueData?.[key + labForm?.length]?.LBORREPARAMETER) === "<"
                      }
                        value="<">{`<`}</option>
                    </Form.Select>
                    <Form.Control
                      className="border-0 text-center w-100"
                      placeholder="Result"
                      style={{ minWidth: "max-content" }}
                      disabled={disabledData && !!valueData?.[key + labForm?.length]?.LBORRES}
                      onChange={(e) =>
                        handleCustomLabFormChange(
                          "LBTESTNAME",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTNAME || valueData?.[key + labForm?.length]?.LBTESTNAME,
                          "LBTESTCATEGORY",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTCATEGORY || valueData?.[key + labForm?.length]?.LBTESTCATEGORY,
                          "LBTESTSUBCATEGORY",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTSUBCATEGORY || valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY,
                          "LBORREPARAMETER",
                          labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER || valueData?.[key + labForm?.length]?.LBORREPARAMETER,
                          "LBORRES",
                          e.target.value,
                          "LBORRESU",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRESU || valueData?.[key + labForm?.length]?.LBORRESU,
                          "LBORNRLO",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRLO || valueData?.[key + labForm?.length]?.LBORNRLO,
                          "LBORNRHI",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRHI || valueData?.[key + labForm?.length]?.LBORNRHI,
                          "LBORNRIND",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRIND || valueData?.[key + labForm?.length]?.LBORNRIND,
                          "LBCLSIG",
                          labFormDataEntry?.[key + labForm?.length]?.LBCLSIG || valueData?.[key + labForm?.length]?.LBCLSIG,
                          "LBCOM",
                          labFormDataEntry?.[key + labForm?.length]?.LBCOM || valueData?.[key + labForm?.length]?.LBCOM,
                          key + labForm?.length,
                          newObjectData,
                          valueData,
                          disabledData && !!valueData?.[key + labForm?.length]?.LBORRES
                        )
                      }
                      value={
                        labFormDataEntry?.[key + labForm?.length]?.LBORRES ||
                        valueData?.[key + labForm?.length]?.LBORRES
                      }
                      type={row.labTestResultDataType || "text"}
                    />
                  </div>
                </td>
                <td
                  className="p-0 text-center"
                  style={{ background: (disabledData && !!valueData?.[key + labForm?.length]?.LBORRESU) && "#e9ecef" }}
                >
                  <Form.Control
                    disabled={disabledData && !!valueData?.[key + labForm?.length]?.LBORRESU}
                    // style={{ width: "max-content" }}
                    className="border-0 text-center"
                    placeholder="Unit"
                    onChange={(e) =>
                      handleCustomLabFormChange(
                        "LBTESTNAME",
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTNAME || valueData?.[key + labForm?.length]?.LBTESTNAME,
                        "LBTESTCATEGORY",
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTCATEGORY || valueData?.[key + labForm?.length]?.LBTESTCATEGORY,
                        "LBTESTSUBCATEGORY",
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTSUBCATEGORY || valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY,
                        "LBORREPARAMETER",
                        labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER || valueData?.[key + labForm?.length]?.LBORREPARAMETER,
                        "LBORRES",
                        labFormDataEntry?.[key + labForm?.length]?.LBORRES || valueData?.[key + labForm?.length]?.LBORRES,
                        "LBORRESU",
                        e.target.value,
                        "LBORNRLO",
                        labFormDataEntry?.[key + labForm?.length]?.LBORNRLO || valueData?.[key + labForm?.length]?.LBORNRLO,
                        "LBORNRHI",
                        labFormDataEntry?.[key + labForm?.length]?.LBORNRHI || valueData?.[key + labForm?.length]?.LBORNRHI,
                        "LBORNRIND",
                        labFormDataEntry?.[key + labForm?.length]?.LBORNRIND || valueData?.[key + labForm?.length]?.LBORNRIND,
                        "LBCLSIG",
                        labFormDataEntry?.[key + labForm?.length]?.LBCLSIG || valueData?.[key + labForm?.length]?.LBCLSIG,
                        "LBCOM",
                        labFormDataEntry?.[key + labForm?.length]?.LBCOM || valueData?.[key + labForm?.length]?.LBCOM,
                        key + labForm?.length,
                        newObjectData,
                        valueData,
                        disabledData && !!valueData?.[key + labForm?.length]?.LBORRESU
                      )
                    }
                    value={
                      labFormDataEntry?.[key + labForm?.length]?.LBORRESU || valueData?.[key + labForm?.length]?.LBORRESU || ""
                    }
                    type={"text"}
                  />
                </td>
                {/* <td
                  className="p-0"
                  style={{ background: disabledData && "#e9ecef" }}
                >
                  <Form.Control
                    disabled={disabledData}
                    className="border-0 text-center"
                    placeholder="Lower Limit"
                    onChange={(e) =>
                      handleCustomLabFormChange(
                        "LBTESTNAME",
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTNAME || valueData?.[key + labForm?.length]?.LBTESTNAME,
                        "LBTESTCATEGORY",
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTCATEGORY || valueData?.[key + labForm?.length]?.LBTESTCATEGORY,
                        "LBTESTSUBCATEGORY",
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTSUBCATEGORY || valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY,
                        "LBORREPARAMETER",
                        labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER || valueData?.[key + labForm?.length]?.LBORREPARAMETER,
                        "LBORRES",
                        labFormDataEntry?.[key + labForm?.length]?.LBORRES || valueData?.[key + labForm?.length]?.LBORRES,
                        "LBORRESU",
                        labFormDataEntry?.[key + labForm?.length]?.LBORRESU || valueData?.[key + labForm?.length]?.LBORRESU,
                        "LBORNRLO",
                        e.target.value,
                        "LBORNRHI",
                        labFormDataEntry?.[key + labForm?.length]?.LBORNRHI || valueData?.[key + labForm?.length]?.LBORNRHI,
                        "LBORNRIND",
                        labFormDataEntry?.[key + labForm?.length]?.LBORNRIND || valueData?.[key + labForm?.length]?.LBORNRIND,
                        "LBCLSIG",
                        labFormDataEntry?.[key + labForm?.length]?.LBCLSIG || valueData?.[key + labForm?.length]?.LBCLSIG,
                        "LBCOM",
                        labFormDataEntry?.[key + labForm?.length]?.LBCOM || valueData?.[key + labForm?.length]?.LBCOM,
                        key + labForm?.length,
                        newObjectData
                      )
                    }
                    value={
                      labFormDataEntry?.[key + labForm?.length]?.LBORNRLO ||
                      valueData?.[key + labForm?.length]?.LBORNRLO
                    }
                    type={"text"}
                  />
                </td> */}
                {/* <td
                  className="p-0"
                  style={{ background: disabledData && "#e9ecef" }}
                >
                  <Form.Control
                    disabled={disabledData}
                    className="border-0 text-center"
                    placeholder="Upper Limit"
                    onChange={(e) =>
                      handleCustomLabFormChange(
                        "LBTESTNAME",
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTNAME || valueData?.[key + labForm?.length]?.LBTESTNAME,
                        "LBTESTCATEGORY",
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTCATEGORY || valueData?.[key + labForm?.length]?.LBTESTCATEGORY,
                        "LBTESTSUBCATEGORY",
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTSUBCATEGORY || valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY,
                        "LBORREPARAMETER",
                        labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER || valueData?.[key + labForm?.length]?.LBORREPARAMETER,
                        "LBORRES",
                        labFormDataEntry?.[key + labForm?.length]?.LBORRES || valueData?.[key + labForm?.length]?.LBORRES,
                        "LBORRESU",
                        labFormDataEntry?.[key + labForm?.length]?.LBORRESU || valueData?.[key + labForm?.length]?.LBORRESU,
                        "LBORNRLO",
                        labFormDataEntry?.[key + labForm?.length]?.LBORNRLO || valueData?.[key + labForm?.length]?.LBORNRLO,
                        "LBORNRHI",
                        e.target.value,
                        "LBORNRIND",
                        labFormDataEntry?.[key + labForm?.length]?.LBORNRIND || valueData?.[key + labForm?.length]?.LBORNRIND,
                        "LBCLSIG",
                        labFormDataEntry?.[key + labForm?.length]?.LBCLSIG || valueData?.[key + labForm?.length]?.LBCLSIG,
                        "LBCOM",
                        labFormDataEntry?.[key + labForm?.length]?.LBCOM || valueData?.[key + labForm?.length]?.LBCOM,
                        key + labForm?.length,
                        newObjectData
                      )
                    }
                    value={
                      labFormDataEntry?.[key + labForm?.length]?.LBORNRHI ||
                      valueData?.[key + labForm?.length]?.LBORNRHI
                    }
                    type={"text"}
                  />
                </td> */}
                <td className="text-center">
                  <Form.Label className="m-0">
                    <Form.Check
                      disabled={disabledData && !!valueData?.[key + labForm?.length]?.LBORNRIND}
                      className="rounded-0"
                      name={`labformnormalype_${key + labForm?.length}`}
                      type="radio"
                      value="Normal"
                      onChange={(e) =>
                        handleCustomLabFormChange(
                          "LBTESTNAME",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTNAME || valueData?.[key + labForm?.length]?.LBTESTNAME,
                          "LBTESTCATEGORY",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTCATEGORY || valueData?.[key + labForm?.length]?.LBTESTCATEGORY,
                          "LBTESTSUBCATEGORY",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTSUBCATEGORY || valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY,
                          "LBORREPARAMETER",
                          labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER || valueData?.[key + labForm?.length]?.LBORREPARAMETER,
                          "LBORRES",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRES || valueData?.[key + labForm?.length]?.LBORRES,
                          "LBORRESU",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRESU || valueData?.[key + labForm?.length]?.LBORRESU,
                          "LBORNRLO",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRLO || valueData?.[key + labForm?.length]?.LBORNRLO,
                          "LBORNRHI",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRHI || valueData?.[key + labForm?.length]?.LBORNRHI,
                          "LBORNRIND",
                          e.target.value,
                          "LBCLSIG",
                          labFormDataEntry?.[key + labForm?.length]?.LBCLSIG || valueData?.[key + labForm?.length]?.LBCLSIG,
                          "LBCOM",
                          labFormDataEntry?.[key + labForm?.length]?.LBCOM || valueData?.[key + labForm?.length]?.LBCOM,
                          key + labForm?.length,
                          newObjectData,
                          valueData,
                          disabledData && !!valueData?.[key + labForm?.length]?.LBORNRIND
                        )
                      }
                      checked={
                        (labFormDataEntry?.[key + labForm?.length]?.LBORNRIND ||
                          valueData?.[key + labForm?.length]?.LBORNRIND) === "Normal"
                      }
                    />
                  </Form.Label>
                </td>
                <td className="text-center">
                  <Form.Label className="m-0">
                    <Form.Check
                      disabled={disabledData && !!valueData?.[key + labForm?.length]?.LBORNRIND}
                      name={`labformnormalype_${key + labForm?.length}`}
                      type="radio"
                      value="Abnormal"
                      onChange={(e) =>
                        handleCustomLabFormChange(
                          "LBTESTNAME",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTNAME || valueData?.[key + labForm?.length]?.LBTESTNAME,
                          "LBTESTCATEGORY",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTCATEGORY || valueData?.[key + labForm?.length]?.LBTESTCATEGORY,
                          "LBTESTSUBCATEGORY",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTSUBCATEGORY || valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY,
                          "LBORREPARAMETER",
                          labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER || valueData?.[key + labForm?.length]?.LBORREPARAMETER,
                          "LBORRES",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRES || valueData?.[key + labForm?.length]?.LBORRES,
                          "LBORRESU",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRESU || valueData?.[key + labForm?.length]?.LBORRESU,
                          "LBORNRLO",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRLO || valueData?.[key + labForm?.length]?.LBORNRLO,
                          "LBORNRHI",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRHI || valueData?.[key + labForm?.length]?.LBORNRHI,
                          "LBORNRIND",
                          e.target.value,
                          "LBCLSIG",
                          e.target.value === "Normal" ? "" : (labFormDataEntry?.[key + labForm?.length]?.LBCLSIG || valueData?.[key + labForm?.length]?.LBCLSIG),
                          "LBCOM",
                          labFormDataEntry?.[key + labForm?.length]?.LBCOM || valueData?.[key + labForm?.length]?.LBCOM,
                          key + labForm?.length,
                          newObjectData,
                          valueData,
                          disabledData && !!valueData?.[key + labForm?.length]?.LBORNRIND
                        )
                      }
                      checked={
                        (labFormDataEntry?.[key + labForm?.length]?.LBORNRIND ||
                          valueData?.[key + labForm?.length]?.LBORNRIND) === "Abnormal"
                      }
                    />
                  </Form.Label>
                </td>
                <td className="text-center">
                  <Form.Label className="m-0">
                    <Form.Check
                      disabled={(disabledData && !!valueData?.[key + labForm?.length]?.LBCLSIG) || (labFormDataEntry?.[key + labForm?.length]?.LBORNRIND ||
                        valueData?.[key + labForm?.length]?.LBORNRIND) === "Normal"}
                      name={`labformcstype_${key + labForm?.length}`}
                      type="radio"
                      value="CS"
                      onChange={(e) =>
                        handleCustomLabFormChange(
                          "LBTESTNAME",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTNAME || valueData?.[key + labForm?.length]?.LBTESTNAME,
                          "LBTESTCATEGORY",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTCATEGORY || valueData?.[key + labForm?.length]?.LBTESTCATEGORY,
                          "LBTESTSUBCATEGORY",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTSUBCATEGORY || valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY,
                          "LBORREPARAMETER",
                          labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER || valueData?.[key + labForm?.length]?.LBORREPARAMETER,
                          "LBORRES",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRES || valueData?.[key + labForm?.length]?.LBORRES,
                          "LBORRESU",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRESU || valueData?.[key + labForm?.length]?.LBORRESU,
                          "LBORNRLO",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRLO || valueData?.[key + labForm?.length]?.LBORNRLO,
                          "LBORNRHI",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRHI || valueData?.[key + labForm?.length]?.LBORNRHI,
                          "LBORNRIND",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRIND || valueData?.[key + labForm?.length]?.LBORNRIND,
                          "LBCLSIG",
                          e.target.value,
                          "LBCOM",
                          labFormDataEntry?.[key + labForm?.length]?.LBCOM || labFormDataEntry?.[key + labForm?.length]?.LBCOM,
                          key + labForm?.length,
                          newObjectData,
                          valueData,
                          (disabledData && !!valueData?.[key + labForm?.length]?.LBCLSIG) || (labFormDataEntry?.[key + labForm?.length]?.LBORNRIND ||
                            valueData?.[key + labForm?.length]?.LBORNRIND) === "Normal"
                        )
                      }
                      checked={
                        (labFormDataEntry?.[key + labForm?.length]?.LBCLSIG ||
                          valueData?.[key + labForm?.length]?.LBCLSIG) === "CS"
                      }
                    />
                  </Form.Label>
                </td>
                <td className="text-center">
                  <Form.Label className="m-0">
                    <Form.Check
                      disabled={(disabledData && !!valueData?.[key + labForm?.length]?.LBCLSIG) || (labFormDataEntry?.[key + labForm?.length]?.LBORNRIND ||
                        valueData?.[key + labForm?.length]?.LBORNRIND) === "Normal"}
                      name={`labformcstype_${key + labForm?.length}`}
                      type="radio"
                      value={"NCS"}
                      onChange={(e) =>
                        handleCustomLabFormChange(
                          "LBTESTNAME",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTNAME || valueData?.[key + labForm?.length]?.LBTESTNAME,
                          "LBTESTCATEGORY",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTCATEGORY || valueData?.[key + labForm?.length]?.LBTESTCATEGORY,
                          "LBTESTSUBCATEGORY",
                          labFormDataEntry?.[key + labForm?.length]?.LBTESTSUBCATEGORY || valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY,
                          "LBORREPARAMETER",
                          labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER || valueData?.[key + labForm?.length]?.LBORREPARAMETER,
                          "LBORRES",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRES || valueData?.[key + labForm?.length]?.LBORRES,
                          "LBORRESU",
                          labFormDataEntry?.[key + labForm?.length]?.LBORRESU || valueData?.[key + labForm?.length]?.LBORRESU,
                          "LBORNRLO",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRLO || valueData?.[key + labForm?.length]?.LBORNRLO,
                          "LBORNRHI",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRHI || valueData?.[key + labForm?.length]?.LBORNRHI,
                          "LBORNRIND",
                          labFormDataEntry?.[key + labForm?.length]?.LBORNRIND || valueData?.[key + labForm?.length]?.LBORNRIND,
                          "LBCLSIG",
                          e.target.value,
                          "LBCOM",
                          labFormDataEntry?.[key + labForm?.length]?.LBCOM || valueData?.[key + labForm?.length]?.LBCOM,
                          key + labForm?.length,
                          newObjectData,
                          valueData,
                          (disabledData && !!valueData?.[key + labForm?.length]?.LBCLSIG) || (labFormDataEntry?.[key + labForm?.length]?.LBORNRIND ||
                            valueData?.[key + labForm?.length]?.LBORNRIND) === "Normal"
                        )
                      }
                      checked={
                        (labFormDataEntry?.[key + labForm?.length]?.LBCLSIG ||
                          valueData?.[key + labForm?.length]?.LBCLSIG) === "NCS"
                      }
                    />
                  </Form.Label>
                </td>
                <td
                  className="p-0"
                  style={{ background: (disabledData && !!valueData?.[key + labForm?.length]?.LBCOM) && "#e9ecef" }}
                >
                  <Form.Control
                    disabled={disabledData && !!valueData?.[key + labForm?.length]?.LBCOM}
                    className="border-0 w-100 p-1"
                    placeholder="Remark"
                    style={{ resize: "none" }}
                    onChange={(e) =>
                      handleCustomLabFormChange(
                        "LBTESTNAME",
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTNAME || valueData?.[key + labForm?.length]?.LBTESTNAME,
                        "LBTESTCATEGORY",
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTCATEGORY || valueData?.[key + labForm?.length]?.LBTESTCATEGORY,
                        "LBTESTSUBCATEGORY",
                        labFormDataEntry?.[key + labForm?.length]?.LBTESTSUBCATEGORY || valueData?.[key + labForm?.length]?.LBTESTSUBCATEGORY,
                        "LBORREPARAMETER",
                        labFormDataEntry?.[key + labForm?.length]?.LBORREPARAMETER || valueData?.[key + labForm?.length]?.LBORREPARAMETER,
                        "LBORRES",
                        labFormDataEntry?.[key + labForm?.length]?.LBORRES || valueData?.[key + labForm?.length]?.LBORRES,
                        "LBORRESU",
                        labFormDataEntry?.[key + labForm?.length]?.LBORRESU || valueData?.[key + labForm?.length]?.LBORRESU,
                        "LBORNRLO",
                        labFormDataEntry?.[key + labForm?.length]?.LBORNRLO || valueData?.[key + labForm?.length]?.LBORNRLO,
                        "LBORNRHI",
                        labFormDataEntry?.[key + labForm?.length]?.LBORNRHI || valueData?.[key + labForm?.length]?.LBORNRHI,
                        "LBORNRIND",
                        labFormDataEntry?.[key + labForm?.length]?.LBORNRIND || valueData?.[key + labForm?.length]?.LBORNRIND,
                        "LBCLSIG",
                        labFormDataEntry?.[key + labForm?.length]?.LBCLSIG || valueData?.[key + labForm?.length]?.LBCLSIG,
                        "LBCOM",
                        e.target.value,
                        key + labForm?.length,
                        newObjectData,
                        valueData,
                        disabledData && !!valueData?.[key + labForm?.length]?.LBCOM
                      )
                    }
                    value={
                      labFormDataEntry?.[key + labForm?.length]?.LBCOM || valueData?.[key + labForm?.length]?.LBCOM
                    }
                    as={"textarea"}
                  />
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default LabFormValues;
