import React, { useState } from "react";
import { Table } from "react-bootstrap";
import UseToggle from "../../../Hooks/UseToggle";
import RandomizedRequestButtonData from "./RandomizedRequestButtonData";
import Moment from "react-moment";
import Pagination from "../../ETMF/Pagination/Pagination";

const RandomizeTableData = ({ randomizerList, unblindIWRS, onMouseDown, tableRef, findRandomizedData, requestedData, studyMode }) => {
  const { toggle, setToggle } = UseToggle();
  const [updatedData, setUpdatedData] = useState("");

  const updateHandleData = (item) => {
    setUpdatedData(item)
    setToggle()
  }

  const iwrsPermissionData = JSON.parse(
    localStorage.getItem("iwrsPermissionData")
  );

  let approvalOneIWRS = iwrsPermissionData?.approvalOneIWRSQC || iwrsPermissionData?.approvalOneIWRSLive
  let approvalTwoIWRS = iwrsPermissionData?.approvalTwoIWRSQC || iwrsPermissionData?.approvalTwoIWRSLive
  let approvalThreeIWRS = iwrsPermissionData?.approvalThreeIWRSQC || iwrsPermissionData?.approvalThreeIWRSLive
  let approvalFourIWRS = iwrsPermissionData?.approvalFourIWRSQC || iwrsPermissionData?.approvalFourIWRSLive
  let approvalFiveIWRS = iwrsPermissionData?.approvalFiveIWRSQC || iwrsPermissionData?.approvalFiveIWRSLive
  let approvalIWRS = approvalOneIWRS || approvalTwoIWRS || approvalThreeIWRS || approvalFourIWRS || approvalFiveIWRS

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = randomizerList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(randomizerList?.length / itemsPerPage);

  return (
    <>
      <div
        className="text-center m-0"
        style={{ fontSize: "13px", color: "#333" }}
      >
        {randomizerList?.length > 0 && (
          <Table className="custom-table m-0">
            <thead>
              <tr>
                <th>Site Id</th>
                <th>Block</th>
                <th>Cohort</th>
                <th>Subject Screening Number</th>
                <th>Subject Number</th>
                <th>Randomization Number</th>
                <th>Treatment</th>
                <th>Randomizad By</th>
                <th>Randomizad Date | Time</th>
                {studyMode !== "DBP MODE" &&
                  <>
                    {!unblindIWRS &&
                      <th className="text-center">Status</th>
                    }
                    {!unblindIWRS &&
                      !approvalIWRS &&
                      <th className="text-center">Request</th>
                    }
                  </>
                }
              </tr>
            </thead>
            <tbody>
              {result?.map((item, index) => (
                <tr key={index} className="CP">
                  <td>{item?.site}</td>
                  <td>{item?.Block}</td>
                  <td>{item?.Cohort}</td>
                  <td>{item?.subjectScreening}</td>
                  <td>{item?.subjectNumber}</td>
                  <td>{item?.randomizationNumber}</td>
                  {studyMode === "DBP MODE" ?
                    <td>
                      {item?.Treatment}
                    </td>
                    :
                    <>
                      {(!unblindIWRS && item?.status === "blinding" && !requestedData?.status) ?
                        <td style={{ filter: 'blur(5px)' }}>
                          Restricted
                        </td>
                        :
                        <td>
                          {item?.Treatment}
                        </td>
                      }
                    </>
                  }
                  <td title={item?.randomizedBy}>{item?.fullName}</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss">
                      {item?.randomizedDT}
                    </Moment>
                  </td>
                  {studyMode !== "DBP MODE" &&
                    <>
                      {!unblindIWRS &&
                        <td style={{ fontWeight: 600 }} className={`${(item?.status === "unblinding" || requestedData?.status) ? "text-success" : "text-danger"} text-center text-capitalize`}>{requestedData?.status ? "Unblinding" : item?.status}</td>
                      }
                      {!unblindIWRS &&
                        !approvalIWRS &&
                        <td className="text-center">
                          <button
                            disabled={item?.status === "unblinding"}
                            onClick={() => updateHandleData(item)}
                            className={`text-white ${item?.status === "unblinding" ? "border-success bg-success border" : (item?.requestSent ? "border-secondary bg-secondary border" : "border-success bg-success border")}`}
                            style={{
                              fontSize: 10,
                              borderRadius: 3,
                              padding: "2px 8px",
                            }}
                          >
                            {item?.status === "unblinding" ? "Approved" : (item?.requestSent ? "Request Sent" : "Request Now")}
                          </button>
                        </td>
                      }
                    </>
                  }
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <div className="position-sticky bottom-0">
          <Pagination
            totalPage={totalPage}
            pageNumber={pageNumber}
            itemsPerPage={itemsPerPage}
            setPageNumber={setPageNumber}
            setItemsPerPage={setItemsPerPage}
            totalItems={randomizerList?.length}
          />
        </div>
      </div>
      <RandomizedRequestButtonData
        Show={!toggle}
        Hide={setToggle}
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        Title={"Table Properties"}
        updatedData={updatedData}
        findRandomizedData={findRandomizedData}
        randomizedId={requestedData?._id}
      />
    </>
  );
};

export default RandomizeTableData;
