import React, { useState } from "react";
import { Table } from "react-bootstrap";
import SplitPane from "react-split-pane";
import Pagination from "../../ETMF/Pagination/Pagination";

const CTMSSiteTableData = ({
    studyDynamicColumn,
    studyDynamicRow,
    hideColumn,
    setIdUpdateRowTable,
    handleSubmit3,
    getStudyRightSiteData,
    setIsSectionVisible,
    isSectionVisible,
    tableRef,
    onMouseDown,
    totalPage,
    startIndex,
    pageNumber,
    itemsPerPage,
    totalItems,
    setPageNumber,
    setItemsPerPage,
    setUpdatedType,
    viewTypeData,
    selectedRow,
    setSelectedRow
}) => {
    return (
        <>
            <SplitPane split="vertical" minSize={20} defaultSize="100%">
                <Table ref={tableRef} className="m-0 custom-table" hover striped>
                    <thead className="position-sticky z-1">
                        <tr>
                            <th
                                onMouseDown={(e) => onMouseDown(e, 0)}
                                style={{ width: 45 }}
                            >
                                S No.
                            </th>
                            {hideColumn?.includes("Name") && (
                                <th onMouseDown={(e) => onMouseDown(e, 1)}>Name</th>
                            )}

                            {studyDynamicColumn &&
                                studyDynamicColumn?.map(({ name }, i) => (
                                    <th onMouseDown={(e) => onMouseDown(e, i + 2)}>{name}</th>
                                ))}

                            {viewTypeData === "ctms" && (
                                <th className="text-center" style={{ width: 80 }} onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                                    Update
                                </th>
                            )}
                            {viewTypeData === "ctms" && (
                                <th className="text-center" style={{ width: 80 }} onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                                    Delete
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {studyDynamicRow?.map((item, index) => (
                            <tr
                                key={index}
                                style={{ cursor: "pointer" }}
                                className={
                                    selectedRow === item._id &&
                                    "highlighted"
                                }
                                onClick={() => {
                                    getStudyRightSiteData(item);
                                    setSelectedRow(item?._id);
                                    setIsSectionVisible(true);
                                }}
                            >
                                <td>
                                    {startIndex + index + 1}
                                </td>
                                {hideColumn?.includes("Name") && (
                                    <td
                                        onClick={() => {
                                            getStudyRightSiteData(item);
                                            setSelectedRow(item?._id);
                                            setIsSectionVisible(true);
                                        }}
                                    >
                                        {item?.name}
                                    </td>
                                )}
                                {Object?.values(item?.row).map((data, index) => (
                                    <td
                                        onClick={() => {
                                            getStudyRightSiteData(item);
                                            setSelectedRow(item?._id);
                                            setIsSectionVisible(true);
                                        }}
                                        key={index}>
                                        {data.valueData}
                                    </td>
                                ))}

                                {viewTypeData === "ctms" && (
                                    <>
                                        <td className="text-center">
                                            <button
                                                onClick={() => {
                                                    setIdUpdateRowTable(item);
                                                    setUpdatedType("editRow");
                                                }}
                                                className="text-white border-success bg-success border"
                                                style={{
                                                    fontSize: 10,
                                                    borderRadius: 3,
                                                    padding: "2px 8px",
                                                }}
                                            >
                                                Update
                                            </button>
                                        </td>
                                        <td className="text-center">
                                            <button
                                                onClick={(e) => handleSubmit3(e, item?._id)}
                                                className="text-white border-danger bg-danger border"
                                                style={{
                                                    fontSize: 10,
                                                    borderRadius: 3,
                                                    padding: "2px 8px",
                                                }}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="position-sticky bottom-0">
                    {totalItems > 0 && (
                        <Pagination
                            totalPage={totalPage}
                            pageNumber={pageNumber}
                            itemsPerPage={itemsPerPage}
                            totalItems={totalItems}
                            setPageNumber={setPageNumber}
                            setItemsPerPage={setItemsPerPage}
                        />
                    )}
                </div>
            </SplitPane>
        </>
    );
};

export default CTMSSiteTableData;
