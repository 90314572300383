import React from "react";
import { Form, Table } from "react-bootstrap";

const PhysicalExaminationFormValues = ({
  physicalExamination = [],
  item,
  handlePhysicalExaminationChange,
  physicalExaminationDataEntry,
  valueData,
  disabledData,
  tableRef,
  onMouseDown,
}) => {
  return (
    <div className="overflow-auto">
      <Table
        bordered
        ref={tableRef}
        className="text-center border-start border-end mt-3 w-100"
      >
        <thead style={{ background: "#b8cfdf" }}>
          <tr style={{ verticalAlign: "bottom" }}>
            <th
              onMouseDown={(e) => onMouseDown(e, 0)}
              className="p-1 text-start"
              rowSpan={2}
            >
              <Form.Label className="m-0">
                Examination Test or Examination Name
              </Form.Label>
            </th>
            {item?.resultType === "indicator" ?
              <>
                <th className="p-1" colSpan={2}
                  onMouseDown={(e) => onMouseDown(e, 1)}>
                  <Form.Label className="m-0">Reference Range Indicator</Form.Label>
                </th>
                <th className="p-1" colSpan={2}
                  onMouseDown={(e) => onMouseDown(e, 2)}>
                  <Form.Label className="m-0">Clinical Significance</Form.Label>
                </th>
              </>
              : item?.resultType === "custom" &&
              <th className="p-1 text-center" onMouseDown={(e) => onMouseDown(e, 1)} colSpan={item?.customOption ? Number(item?.customOption) : 0}>
                <Form.Label className="m-0">Result</Form.Label>
              </th>
            }
            <th onMouseDown={(e) => onMouseDown(e, item?.resultType === "indicator" ? 3 : 1)} rowSpan={2}>
              <Form.Label className="m-0">
                Remark &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Form.Label>
            </th>
            <th rowSpan={2} onMouseDown={(e) => onMouseDown(e, item?.resultType === "indicator" ? 4 : 2)}></th>
          </tr>
          <tr>
            {item?.resultType === "indicator" ?
              <>
                <td className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
                  <Form.Label className="m-0">Normal</Form.Label>
                </td>
                <td className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
                  <Form.Label className="m-0">Abnormal</Form.Label>
                </td>
                <th onMouseDown={(e) => onMouseDown(e, 9)} className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
                  <Form.Label className="m-0">CS</Form.Label>
                </th>
                <th onMouseDown={(e) => onMouseDown(e, 10)} className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
                  <Form.Label className="m-0">NCS</Form.Label>
                </th>
              </>
              : item?.resultType === "custom" &&
              <>
                {Array.from({ length: item?.customOption || 0 }).map((_, i) => (
                  <td className="p-1">
                    <Form.Label className="m-0">{(i === 0) ? item?.customOptionOne : (i === 1) ? item?.customOptionTwo : (i === 2) ? item?.customOptionThree : (i === 3) ? item?.customOptionFour : (i === 4) ? item?.customOptionFive : (i === 5) ? item?.customOptionSix : ""}</Form.Label>
                  </td>
                ))}
              </>
            }
          </tr>
        </thead>
        <tbody>
          {physicalExamination?.map((row, key) => (
            <tr key={key}>
              <td className="position-sticky start-0 bg-white">
                <Form.Label className="m-0">
                  {row.physicalExaminationName}
                </Form.Label>
              </td>
              {item?.resultType === "indicator" ?
                <>
                  <td className="text-center">
                    <Form.Check
                      disabled={disabledData && !!valueData?.[key]?.PEORRES}
                      name={`physicalexaminationnormalype_${key}`}
                      type="radio"
                      value="Normal"
                      onChange={(e) =>
                        handlePhysicalExaminationChange(
                          "PEORRES",
                          e.target.value,
                          "PECLSIG",
                          physicalExaminationDataEntry?.[key]?.PECLSIG || valueData?.[key]?.PECLSIG,
                          "PECOM",
                          physicalExaminationDataEntry?.[key]?.PECOM || valueData?.[key]?.PECOM,
                          key,
                          item,
                          valueData,
                          disabledData && !!valueData?.[key]?.PEORRES
                        )
                      }
                      checked={
                        (physicalExaminationDataEntry?.[key]?.PEORRES ||
                          valueData?.[key]?.PEORRES) === "Normal"
                      }
                    />
                  </td>
                  <td className="text-center">
                    <Form.Check
                      disabled={disabledData && !!valueData?.[key]?.PEORRES}
                      name={`physicalexaminationnormalype_${key}`}
                      type="radio"
                      value="Abnormal"
                      onChange={(e) =>
                        handlePhysicalExaminationChange(
                          "PEORRES",
                          e.target.value,
                          "PECLSIG",
                          e.target.value === "Normal" ? "" : (physicalExaminationDataEntry?.[key]?.PECLSIG || valueData?.[key]?.PECLSIG),
                          "PECOM",
                          physicalExaminationDataEntry?.[key]?.PECOM || valueData?.[key]?.PECOM,
                          key,
                          item,
                          valueData,
                          disabledData && !!valueData?.[key]?.PEORRES
                        )
                      }
                      checked={
                        (physicalExaminationDataEntry?.[key]?.PEORRES ||
                          valueData?.[key]?.PEORRES) === "Abnormal"
                      }
                    />
                  </td>
                </>
                : item?.resultType === "custom" &&
                <>
                  {Array.from({ length: item?.customOption || 0 }).map((_, i) => (
                    <td key={i} className="p-0 text-center">
                      <Form.Check
                        type="radio"
                        name={`physicalexaminationcustomype_${key}`}
                        value={(i === 0) ? item?.customOptionOne : (i === 1) ? item?.customOptionTwo : (i === 2) ? item?.customOptionThree : (i === 3) ? item?.customOptionFour : (i === 4) ? item?.customOptionFive : (i === 5) ? item?.customOptionSix : ""}
                        disabled={disabledData && valueData?.[key]?.PEORRES}
                        onChange={(e) =>
                          handlePhysicalExaminationChange(
                            "PEORRES",
                            e.target.value,
                            "PECLSIG",
                            physicalExaminationDataEntry?.[key]?.PECLSIG || valueData?.[key]?.PECLSIG,
                            "PECOM",
                            physicalExaminationDataEntry?.[key]?.PECOM || valueData?.[key]?.PECOM,
                            key,
                            item,
                            valueData,
                            disabledData && valueData?.[key]?.PEORRES
                          )
                        }
                        checked={
                          (physicalExaminationDataEntry?.[key]?.PEORRES ||
                            valueData?.[key]?.PEORRES) === ((i === 0) ? item?.customOptionOne : (i === 1) ? item?.customOptionTwo : (i === 2) ? item?.customOptionThree : (i === 3) ? item?.customOptionFour : (i === 4) ? item?.customOptionFive : (i === 5) ? item?.customOptionSix : "")
                        }
                      />
                    </td>
                  ))}
                </>
              }
              {item?.resultType === "indicator" &&
                <>
                  <td className="text-center">
                    <Form.Check
                      disabled={(disabledData && !!valueData?.[key]?.PECLSIG) || (physicalExaminationDataEntry?.[key]?.PEORRES ||
                        valueData?.[key]?.PEORRES) === "Normal"}
                      name={`physicalexaminationcstype_${key}`}
                      type="radio"
                      value="CS"
                      onChange={(e) =>
                        handlePhysicalExaminationChange(
                          "PEORRES",
                          physicalExaminationDataEntry?.[key]?.PEORRES || valueData?.[key]?.PEORRES,
                          "PECLSIG",
                          e.target.value,
                          "PECOM",
                          physicalExaminationDataEntry?.[key]?.PECOM || valueData?.[key]?.PECOM,
                          key,
                          item,
                          valueData,
                          (disabledData && !!valueData?.[key]?.PECLSIG) || (physicalExaminationDataEntry?.[key]?.PEORRES ||
                            valueData?.[key]?.PEORRES) === "Normal"
                        )
                      }
                      checked={
                        (physicalExaminationDataEntry?.[key]?.PECLSIG ||
                          valueData?.[key]?.PECLSIG) === "CS"
                      }
                    />
                  </td>
                  <td className="text-center">
                    <Form.Check
                      disabled={(disabledData && !!valueData?.[key]?.PECLSIG) || (physicalExaminationDataEntry?.[key]?.PEORRES ||
                        valueData?.[key]?.PEORRES) === "Normal"}
                      name={`physicalexaminationcstype_${key}`}
                      type="radio"
                      value="NCS"
                      onChange={(e) =>
                        handlePhysicalExaminationChange(
                          "PEORRES",
                          physicalExaminationDataEntry?.[key]?.PEORRES || valueData?.[key]?.PEORRES,
                          "PECLSIG",
                          e.target.value,
                          "PECOM",
                          physicalExaminationDataEntry?.[key]?.PECOM || valueData?.[key]?.PECOM,
                          key,
                          item,
                          valueData,
                          (disabledData && !!valueData?.[key]?.PECLSIG) || (physicalExaminationDataEntry?.[key]?.PEORRES ||
                            valueData?.[key]?.PEORRES) === "Normal"
                        )
                      }
                      checked={
                        (physicalExaminationDataEntry?.[key]?.PECLSIG ||
                          valueData?.[key]?.PECLSIG) === "NCS"
                      }
                    />
                  </td>
                </>
              }
              <td
                className="p-0"
                style={{ background: disabledData && "#e9ecef" }}
              >
                <Form.Control
                  disabled={disabledData && !!valueData?.[key]?.PECOM}
                  className="border-0 w-100 p-1"
                  placeholder="Remark"
                  style={{ resize: "none" }}
                  onChange={(e) =>
                    handlePhysicalExaminationChange(
                      "PEORRES",
                      physicalExaminationDataEntry?.[key]?.PEORRES || valueData?.[key]?.PEORRES,
                      "PECLSIG",
                      physicalExaminationDataEntry?.[key]?.PECLSIG || valueData?.[key]?.PECLSIG,
                      "PECOM",
                      e.target.value,
                      key,
                      item,
                      valueData,
                      disabledData && !!valueData?.[key]?.PECOM
                    )
                  }
                  value={
                    physicalExaminationDataEntry?.[key]?.PECOM ||
                    valueData?.[key]?.PECOM
                  }
                  as={"textarea"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default PhysicalExaminationFormValues;
